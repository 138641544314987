import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import applicationSite from "../../assets/img/Sensor/application-site-selection-sm.png"
import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";
import {CiCircleInfo} from "react-icons/ci";

const ReplacingSensor = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');
    const [video, setVideo] = useState(null);

    useEffect(() => {
        setVideo(`/translations/${lang}/video/remove-sensor-sm.mp4`)
    }, [lang])
    useEffect(() => {
        console.log(video)
    }, [video])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={10} className={'text-start ps-4'}>

                <h2> {t("replacingSensor")}</h2>


                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("replacingSensor_Text"))}  </span>
                   <span> <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                       setShow(true)
                       setTitle(t('caution'))
                       setText(t("replacingSensor_Warning"))
                   }}/></span>
                </p>






            </Col>
            <Col md={5} className={''}>

            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/removing-sensor'} nextUrl={'/understanding-readings'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default ReplacingSensor