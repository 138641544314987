import {useTranslation} from "../../Utils/UseTranslation";
import {Col, Row} from "react-bootstrap";
import './../../assets/css/ProductOverview/SensorGlucoseReading.css'
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {useEffect, useState} from "react";

const SensorGlucoseReading = () => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang')
    const [image, setImage]= useState(null)
 /*   const textData = [
        {id: 1, text: t("message"), x: "12%", y: "11.5%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 2, text: t("currentGlucose"), text2: t('currentGlucoseText'), x: "8%", y: "33.5%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 2, text: t("foodNote"),x: "12%", y: "52%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 3, text: t("targetGlucoseRange"), text2: t("targetGlucoseRangeText"), x: "0", y: "65.4%", right: '70%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 5, text: t("addNotes"), text2: t("addNoteText"), x: "67%", y: "5%"},
        {id: 6, text: t("glucoseTrendArrow"),text2: t("glucoseTrendArrowText"), x: "67%", y: "22%"},
        {id: 7, text: t("insulinNote"), x: "67%", y: "51%"},
        {id: 8,text: t("highGlucoseAlarm"),x: "67%",y: "60%"},
        {id: 9,text: t("glucoseGraph"),x: "67%",y: "69%",},
        {id: 10,text: t("lowGlucoseAlarm"), x: "67%",y: "90%"}

    ];*/

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/sensor_readings.png`)
    }, [lang])

    const textData = [
        {id: 1, text: t("message"), text2: t("messageText"), x: "6%", y: "7%", },
        {id: 2, text: t("currentGlucose"), text2: t('currentGlucoseText'), x: "6%", y: "32%", right: '56%'},
        {id: 2, text: t("foodNote"), x: "6%", y: "56%"},
        {id: 5, text: t("addNotes"), text2: t("addNoteText"), x: "70%", y: "7%",  right: '5%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 6, text: t("glucoseTrendArrow"),text2: t("glucoseTrendArrowText"), x: "67%", y: "30%",  right: '5%', class: 'text-end d-flex flex-column align-items-end'},
        {
            id: 6,
            text: t("rapidActInsuline"),
            x: "70%",
            y: "54%",
            right: '5%',
            class: 'text-end d-flex flex-column align-items-end'
        },
        {id: 9,text: t("glucoseGraph"), text2: t('glucoseGraphText'), x: "70%", y: "73%",  right: '5%', class: 'text-end d-flex flex-column align-items-end'},

    ];
    return (
        <>
            <Col md={4} className={'text-start ps-4'}>

                <h2> {t("sensorGlucoseReadingsTitle")}</h2>
                <p>{t("sensorGlucoseReading_Text")}</p>


            </Col>
            <Col md={8} className={'position-relative text-start align-self-center'}>
                <h3 className={'text-center'}>{t("sensorGlucoseReading_Text_1")}</h3>
                <Row className={'image-container'}>


                    <img src={image} className={'img-fluid'}/>
                    <div>  {textData.map(item => (
                        <div
                            key={item.id}
                            className={`text-overlay ${item.class}`}
                            style={{left: item.x, top: item.y, right: item.right && item.right}}
                        >
                            <p className="text mb-1">{item.text}</p>
                            <p className="small secondaryText">{item.text2}</p>
                        </div>
                    ))}
                    </div>
                </Row>


            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/sensor-kit'} nextUrl={'/first-time-reader-setup'}/>
            </Col>
        </>


    )
}

export default SensorGlucoseReading