import {Col, Row} from "react-bootstrap";

import {useTranslation} from "../../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import parse from "html-react-parser";
import {HiExclamationTriangle} from "react-icons/hi2";
import {FiArrowDownRight, FiArrowUpRight} from "react-icons/fi";

const HighLowReader = (props) => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [goingHigh, setGoingHigh] = useState(null);
    const [goingLow, setGoingLow] = useState(null);
    const highLowReadingApp_Text = parse(t('highLowReadingApp_Text').replace('%WARNING_IMG%', '<img src="/icons/warning_icon.png" />'));
    useEffect(() => {
        setGoingHigh(!props.isApp ? `/translations/${lang}//ProductUse/high_reading_lg.png` : `/translations/${lang}/ProductUse/high_low_reading_lg.png`)

        setGoingLow(`/translations/${lang}/ProductUse/low_reading_lg.png`)
    }, [lang])
    return (
        <>
            <Row>
                <Col md={6}>
                    <p> {!props.isApp ? t('highLowReading_Text'): highLowReadingApp_Text}</p>
                   {/* <b>{t('note')}</b>*/}
                    {/*<ul>
                        <li>
                            {t('goingHighLowReading_Note_1')}
                        </li>
                        <li>
                            {t('goingHighLowReading_Note_1')}
                        </li>
                    </ul>*/}
                </Col>

                {!props.isApp ? (
                    <>
                        <Col md={3} className={'text-start ps-4'}>
                            <img src={goingHigh} className={'img-fluid'}/>
                        </Col>
                    <Col md={3} className={'text-start ps-4'}>
                        <img src={goingLow} className={'img-fluid'}/>
                    </Col>
                    </>
                ) : (

                    <Col md={6} className={'text-start ps-4 pt-3'}>
                        <Row className={'text-white text-center py-2 w-75'}
                             style={{background: '#ED1C24'}}>
                            <p className={'fw-bold text-uppercase '}>
                                <HiExclamationTriangle className={'fs-5'}/> {t('Low')}
                            </p>
                            <span className={'fw-bold d-inline-flex justify-content-center align-items-center'} style={{fontSize: '40px'}}>
                                <span className={'text-uppercase'}  style={{fontSize: '80px'}}>
                                    {t('dynamicValues.imagesSensorReadings.lowGlucose')}</span>
                               <span className={'d-flex flex-column'}>
                                    <FiArrowDownRight />
                                <span className={'text-normal fs-5 ms-2'}>{t('dynamicValues.unit')}</span>
                               </span>
                            </span>
                        </Row>
                        <Row className={'text-white text-center py-2 w-75 ms-3'}
                             style={{background: 'orange', marginTop: '-20px'}}>
                            <p className={'fw-bold text-uppercase '}>
                                <HiExclamationTriangle className={'fs-5'}/> {t('High')}
                            </p>
                            <span className={'fw-bold d-inline-flex justify-content-center align-items-center'} style={{fontSize: '40px'}}>
                                <span className={'text-uppercase'}  style={{fontSize: '80px'}}>
                                    {t('dynamicValues.imagesSensorReadings.highGlucose')}
                                </span>
                               <span className={'d-flex flex-column justify-content-start'}>
                                    <FiArrowUpRight />
                                <span className={'text-normal fs-5 ms-2'}>{t('dynamicValues.unit')}</span>
                               </span>
                            </span>

                        </Row>

                    </Col>
                )}

            </Row>

        </>
    )
}
export default HighLowReader