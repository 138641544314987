import {useTranslation} from "../../Utils/UseTranslation";
import {useEffect, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import {CiCircleInfo} from "react-icons/ci";
import {Link} from "react-router-dom";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import video from './../../assets/videos/start-sensor.mp4'
import Video from "../../Components/Layout/Elements/VideoPlayer";

const StartingSensor = () => {
    const {t, i18n} = useTranslation()
    const [turnOnOff, setTurnOnOff] = useState(false)
    const [homeBtnBehavior, setHomeBtnBehavior] = useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const [fromSettingAlarms, setFromSettingAlarms] = useState(true)
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');

    const videoRef = useRef(null);
    const [showReader, setShowReader] = useState(false)


    const playVideo = () => {
        setShowReader(false)
        setTimeout(function () {
            if (videoRef.current) {
                videoRef.current.play();

            }
        }, 500)

    };
    const handleShowReader = () => {
        setShowReader(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("sensorStart")}</h2>
                <p className={'mb-3 '}>
                    <span>{t("sensorStart_Text")}  </span> <br/>
                    {/*<span><b>{t("note")}</b>: {t("sensorStart_Text")}  </span>*/}

                </p>
                <p className={'text-orange fw-bold mb-0 mt-4'}> {t("howTo")}</p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{t("sensorStart_List_1")}  </span>
                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{t("sensorStart_List_2")}  </span>

                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{t("sensorStart_List_3")}  </span>
                </p>

                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                {showReader &&  <Row className={'justify-content-end text-end'}>
                    <Col md={2} className={'text-end'}>
                        <Row> <
                            Link onClick={playVideo} className={'btn bkg-primary btnNav '}> {t('play')}</Link>
                        </Row>
                        <Row className={'mt-2'}>
                            <Link onClick={handleShowReader}
                                  className={'btn bkg-primary btnNav'}> {t('reader')}</Link>
                        </Row>
                    </Col>

                </Row>}

            </Col>
            {!showReader ? (
                <Col md={6} className={'align-self-start position-relative'}>
                    <Video video={video} t={t} startingSensor={true} handleShowReader={handleShowReader} videoRef={videoRef} actionOnEnd={() => {setShowReader(false)}}></Video>

                </Col>
            ) : (
                <Col md={6} className={'align-self-start position-relative'}>
                    <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior}
                                 setCurrentStep={setCurrentStep} currentStep={currentStep} isFromStartingSensor={true}>
                        {turnOnOff &&
                            <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep}
                                                      setCurrentStep={setCurrentStep}
                                                      fromSettingAlarms={fromSettingAlarms}
                                                      sensorIsAlreadyActive={false}
                                                      firstActivation={true}
                            />}

                    </ReaderEmpty>
                </Col>
            )}

            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/removing-sensor'} nextUrl={'/checking-glucose'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default StartingSensor