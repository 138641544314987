import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "../../../Utils/UseTranslation";

const SensorReadings = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);
    const sensorReadingNote_2 = t('sensorReadingNote_2', {
        settingsImageToken: '%TIME_IMG%',
        settingsImageValue: '<img src="/icons/reader_time.png" />'
    });

    const textData = [
        {id: 2, text: t("glucoseTrendArrow"), x: "78%", y: "25%", class: 'text-start'},
        {id: 3, text: t("targetGlucoseRange"), text2: t("targetGlucoseRangeText"), x: "4%", y: "63.4%", right:'69%', class: 'text-start'},

        {id: 6, text: t("currentGlucose"), x: "70%", y: "55%", right: '5%', class: 'text-end d-flex flex-column align-items-end'},


    ];

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/sensor_readings_reader.png`)
    }, [lang])
    return (
        <>
            <Row>
                <Col md={4}>
                    <ul>
                        <p>{t('sensorReadingNote_1')}</p>
                        <p>{sensorReadingNote_2}</p>
                        <p>{t('sensorReadingNote_3')}</p>

                    </ul>
                </Col>
                <Col md={8} sm={8} className={'position-relative text-start align-self-center '}>
                    <Row className={'image-container'}>
                        <img src={image} className={'img-fluid'}/>
                        <div>  {textData.map(item => (
                            <div
                                key={item.id}
                                className={`text-overlay ${item.class}`}
                                style={{left: item.x, top: item.y, right: item.right && item.right}}
                            >
                                <p className="text mb-1">{item.text}</p>
                                <p className="small secondaryText">{item.text2}</p>
                            </div>
                        ))}
                        </div>
                    </Row>
                </Col>
            </Row>


        </>
    )
}
export default SensorReadings