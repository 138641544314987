import {Col, Row} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

import {useTranslation} from "react-i18next";
import HomeScreen from "../../Components/Reader/FirstTimeSetup/HomeScreens/HomeScreen";
import {useEffect, useState} from "react";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import HomeScreenHeader from "../../Components/Reader/FirstTimeSetup/HomeScreens/HomeScreenHeader";

const ReaderHomeScreen = () => {
    const {t, i18n} = useTranslation()
    const [image, setImage] = useState(null);
    const lang = localStorage.getItem('lang')

    const [isDetail, setIsDetail] = useState(true)

    const [imageInactive, setImageInactive] = useState(`/translations/${lang}/noActive.jpg`)
    const [isNote, setIsNote] = useState(false)
    const [audio, setAudio] = useState(null)

    const [foodIcon, setFoodIcon] = useState(false)
    const [insulinIcon, setInsulinIcon] = useState(false)
    useEffect(() => {
        setImage(`/translations/${lang}/readerHomeScreen.png`)
    }, [lang])
    // Array di oggetti testo con le informazioni per il posizionamento
    const textData = [
        {id: 1, text: t("currentTime"), x: "6%", y: "10%"},
        {id: 5, text: t("batteryCharge"), x: "6%", y: "2%"},
        {id: 2, text: t("sensorStatus"), text2: t('sensorStatusText'), x: "6%", y: "31.5%"},
        {id: 2, text: t("viewGlucose"), text2: t("viewGlucoseText"), x: "6%", y: "52.8%"},
        {id: 3, text: t("reviewHistory"), text2: t("reviewHistoryText"), x: "68.3%", y: "58%", right: '4%', class: 'text-end d-flex flex-column align-items-end'},
        {id: 6, text: t("soundVibration"), x: "69%", y: "2%", right: '4%', class: 'text-end d-flex flex-column align-items-end'},
        {
            id: 7,
            text: t("signalStatus"),
            text2: t("signalStatusText"),
            x: "69%",
            y: "10.5%",
            right: "4%",
            class: 'text-end d-flex flex-column align-items-end'
        },
        {
            id: 7,
            text: t("settings"),
            text2: t("settingText"),
            x: "65%",
            y: "32%",
            right: "4%",
            class: 'text-end d-flex flex-column align-items-end'
        }

    ];
    return (
        <>
            <Col md={4} className={'text-start ps-4'}>

                <h2> {t("readerHomeScreen")}</h2>
                <p>{t("readerHomeScreenText")}</p>


            </Col>
            <Col md={8} className={'position-relative text-start align-self-center'}>

                <Row className={'image-container'}>

                {/*    <div className={'p-0'} style={{width: '230px', margin: 'auto', border: '1px solid #dedede', height: '330px'}}>
                        <HomeScreen firstActivation={false} isDetail={isDetail}
                                          behavior={''}
                                          behaviorIndex={''} navigateToComponent={''}
                                          sensorIsActive={false}
                                          t={t} isStatic={true} isFromAddingNotes={false}/>
                    </div>*/}
                    <img src={image} className={'img-fluid'}/>
                    <div>  {textData.map(item => (
                        <div
                            key={item.id}
                            className={`text-overlay ${item.class}`}
                            style={{left: item.x, top: item.y, right: item.right && item.right}}
                        >
                            <p className="text mb-1">{item.text}</p>
                            <p className="small secondaryText">{item.text2}</p>
                        </div>
                    ))}
                    </div>
                </Row>


            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/first-time-reader-setup'} nextUrl={'/setting-alarms'}/>
            </Col>
        </>


    )
}

export default ReaderHomeScreen