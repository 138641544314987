import {useTranslation} from "../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";

import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import SensorReadings from "../../Components/ProductUse/UnderstandingSensor/SensorReadings";
import TrendArrows from "../../Components/ProductUse/UnderstandingSensor/TrendArrows";
import GoingHighLowReader from "../../Components/ProductUse/UnderstandingSensor/GoingHighLowReader";
import HighLowReader from "../../Components/ProductUse/UnderstandingSensor/HighLowReader";
import HiLoReader from "../../Components/ProductUse/UnderstandingSensor/HiLoReader";

const UnderstandingReadings  = () => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/checking_app.jpg`)
    }, [lang])
    useEffect(() => {
        console.log(image)
    }, [image])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={12} className={'text-start ps-4'}>

                <h2> {t("understandingSensor")} - {t('reader')}</h2>
                <Tabs
                    defaultActiveKey="sensorReading"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                    justify
                >
                    <Tab eventKey="sensorReading" title={t("sensorReading")}>
                        <SensorReadings/>
                    </Tab>
                    <Tab eventKey="trendArrows" title={t("trendArrows")}>
                        <TrendArrows/>
                    </Tab>
                    <Tab eventKey="goingHighLow" title={t("goingHighLow")}>
                        <GoingHighLowReader/>
                    </Tab>
                    <Tab eventKey="HighLow" title={t("HighLow")}>
                      <HighLowReader />
                    </Tab>
                    <Tab eventKey="HiLo" title={t("HiLo")}>
                   <HiLoReader />
                    </Tab>
                </Tabs>


            </Col>
            {/* <Col md={5} className={'align-self-start position-relative'}>

                <img src={image} className={'img-fluid'}/>
            </Col>*/}
            <Col md={12} className={'text-start align-self-end'}>

                <NavigationButtons t={t} prevUrl={'/checking-glucose'} nextUrl={'/using-glucose-alarms'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default UnderstandingReadings
