import {Col, Row} from "react-bootstrap";

import parse from "html-react-parser";
import {useTranslation} from "../../../Utils/UseTranslation";
import {useEffect, useState} from "react";

import './../../../assets/css/ProductUse.css'

const Lunch = () => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState([]);
    const [imageApp, setImageApp] = useState([]);
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lunch_Text = t('lunch_Text', {
        settingsImageToken: '%ARROW_UP_ICON%',
        settingsImageValue: `<img src="/icons/arrow_2.png" />`
    });

    const lunch_Text_2 = t('lunch_Text_2', {
        settingsImageToken: '%ARROW_UP_ICON%',
        settingsImageValue: `<img src="/icons/arrow_2.png" />`
    });
    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/treatmentGuide/reader_115.png`)
        setImageApp(`/translations/${lang}/ProductUse/treatmentGuide/app_115.png`)
    }, [lang])
    useEffect(() => {

    }, [image])

    return (

        <Row>
            <Col md={7} className={'text-start '}>
                <p className={''}>
                    <b>{t("whatYouSee")}: </b> {lunch_Text}
                </p>
               <Row className={'align-content-start'}>
                   <Col md={2} className={'d-flex justify-content-around'}>
                       <p className={'fw-bold italic'}>{t("beforeLunch")}</p>
                   </Col>
                   <Col md={3} className={'offset-2'}>
                       <div className={'text-center'}>

                           <p className={'mx-0 mb-0'}>
                               <b> {t('reader')}</b>
                           </p>
                           <img src={image} className={'img-fluid w-75'}/>
                       </div>
                   </Col>
                  {/* <Col md={3} className={'mt-0'}>
                       <div className={'text-center'}>

                           <p className={'mx-0 mb-0'}>
                               <b> {t('app')}</b>
                           </p>
                           <img src={imageApp} className={'img-fluid w-75'}/>
                       </div>
                   </Col>*/}
                   <p className={'p-2'}>
                       {lunch_Text_2}
                   </p>
                   <Col md={2}>
                       <p className={'fw-bold italic'}>{t("afterLunch")}</p>
                   </Col>
                   <Col md={3} className={'offset-2'}>
                       <div className={'text-center'}>

                           <p className={'mx-0 mb-0'}>
                               <b> {t('reader')}</b>
                           </p>
                           <img src={image} className={'img-fluid w-75'}/>
                       </div>
                   </Col>
                  {/* <Col md={3}>
                       <div className={'text-center'}>

                           <p className={'mx-0 mb-0'}>
                               <b> {t('app')}</b>
                           </p>
                           <img src={imageApp} className={'img-fluid w-75'}/>
                       </div>
                   </Col>*/}
               </Row>

            </Col>
            <Col md={5} className={'align-items-start d-inline-flex  px-5'}>

                <p className={'p-2 treatmentColoured'}>
                    <b>{t("whatMeans")}: </b> {parse(t('lunch_Text_3'))}
                    <p className={'mb-0 mt-2'}><i>{t("forExample")}</i></p>
                    <ul className={'mt-'}>
                        <li>{t('lunch_List_1')}</li>
                        <li>{t('lunch_List_2')}</li>

                    </ul>
                </p>


            </Col>


         {/*   <Col className={'text-start'}>
                <NavigationButtons t={t} prevUrl={'/replacing-sensor'} nextUrl={'/understanding-readings'}/>
            </Col>*/}
        </Row>

    )
}

export default Lunch