import {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";

import {useTranslation} from "../../Utils/UseTranslation";

import video from './../../assets/videos/prep-applicator.mp4'
import Video from "../../Components/Layout/Elements/VideoPlayer";
const PreparingSensorApplicator = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');

    const videoRef = useRef(null);

    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("preparingSensorApplicator")}</h2>


                <p className={''}>
                    <span>{t("preparingSensorApplicator_Text")}  </span>
                </p>
                <p className={'text-orange fw-bold mb-0 mt-2 d-flex align-items-center justify-content-between'}> {t("howTo")}
                    <span>
                        <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('caution'))
                            setText(<ul>
                                <li>
                                    {t('preparingSensorApplicator_Caution_1')}
                                </li>
                                <li>
                                    {t('preparingSensorApplicator_Caution_2')}
                                </li>

                            </ul>)
                        }}/>
                    </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("preparingSensorApplicator_List_1")} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("preparingSensorApplicator_List_2")} </span>
                    <span>
                        <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('caution'))
                            setText(t('preparingSensorApplicator_Caution_3'))
                        }}/>
                    </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("preparingSensorApplicator_List_3")} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("preparingSensorApplicator_List_4")} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("preparingSensorApplicator_List_5")} </span>
                </p>


            </Col>
            <Col md={6} className={''}>

                {video && <Video video={video} t={t} videoRef={videoRef}  />}
            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/preparing-application-site'} nextUrl={'/applying-sensor'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default PreparingSensorApplicator