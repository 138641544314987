import {Col, Row} from "react-bootstrap";

import parse from "html-react-parser";

import {useTranslation} from "../../../Utils/UseTranslation";
import {useEffect, useState} from "react";

import './../../../assets/css/ProductUse.css'
import GlucoseReadings from "../../Reader/FirstTimeSetup/HomeScreens/GlucoseReadings";

const BeforeBreakfast = () => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState([]);
    const [imageApp, setImageApp] = useState([]);
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const beforeDinner_text = t('beforeDinner_text', {
        settingsImageToken: '%ARROW_DOWN%',
        settingsImageValue: `<img src="/icons/arrow_4.png" />`
    });

    const beforeDinner_list_2 =t('beforeDinner_list_2', {
        settingsImageToken: '%ARROW_DOWN%',
        settingsImageValue: `<img src="/icons/arrow_4.png" />`
    });

    const [behaviorIndex, setBehaviorIndex] = useState(0);
    const behavior = ['reader_115']

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/treatmentGuide/reader_115.png`)
        setImageApp(`/translations/${lang}/ProductUse/treatmentGuide/app_115.png`)
    }, [lang])
    useEffect(() => {
        console.log(image)
    }, [image])

    return (

        <Row>
            <Col md={4} className={'text-start '}>
                <p className={'p-2'}>
                    <b>{t("whatYouSee")}: </b> {beforeDinner_text}
                </p>
                <p className={'p-2 treatmentColoured'}>
                    <b>{t("whatMeans")}: </b> {parse(t('beforeBreakfast_Text_2'))}
                    <p className={'mb-0 mt-2'}><i>{t("forExample")}</i></p>
                    <ul className={'mt-'}>
                        <li>{t('beforeDinner_list_1')}</li>
                        <li>{beforeDinner_list_2}</li>
                    </ul>
                </p>

            </Col>
            <Col md={8} className={'align-items-center justify-content-center d-inline-flex  px-5'}>
                <div className={'text-center'}>
                    <p className={'mx-0'}>
                        <b> {t('reader')}</b>
                    </p>
                    <div style={{width: '230px'}}>
                        <GlucoseReadings navigateToComponent={''} setFirstActivation={''}
                                         sensorIsActive={true} setSensorIsActive={''}
                                         firstActivation={false} behaviorIndex={behaviorIndex}
                                         behavior={behavior} isStatic={true}/></div>

                </div>
            </Col>
        </Row>

    )
}

export default BeforeBreakfast