import {Col, Row} from "react-bootstrap";
import sensor from "../../assets/img/ProductOverview/SensorKit/sensorKit.png";
import sensorUk from "../../assets/img/ProductOverview/SensorKit/sensorKitUk.jpg";
import applicator from "../../assets/img/ProductOverview/sensor_applicator.png";
import {useTranslation} from "../../Utils/UseTranslation";
import parse from 'html-react-parser';
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import {useState} from "react";

const SensorKit = () => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()

    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={5} className={'text-start ps-4'}>

                <h2> {t("sensorKitTitle")}</h2>
                <p>{t("sensorKitText_1")}</p>
                <p> {t("sensorKitText_impo")}</p>

            </Col>
            <Col md={7} sm={12} className={'position-relative text-start align-self-center'}>
                <Row className={'image-container'}>


                    <img src={lang === 'en' ? sensorUk : sensor} className={'img-fluid'}/>
                    <div>
                       {/* <div style={{ right: 0, top: '0%'}}  className={`text-overlay `} >
                            <p className={'fw-bold mb-0'}> {parse(t("sensorApplicator"))}</p>
                            <p> {t("sensorKit_upText")}</p>

                        </div>*/}
                        <div style={{ left:'15%', top: '10%'}}  className={`text-overlay `} >
                            <p className={'fw-bold mb-0'}> {parse(t("sensorApplicator"))}</p>
                            <p> {t("sensorKit_upText")}</p>

                        </div>
                        <div style={{ left: 0, top: '85%', width: '250px'}}  className={`text-overlay `} >

                            <p className={'fw-bold mb-0'}> {parse(t("sensor"))}</p>
                            <p> {t("sensor_upText")}</p>

                        </div>

                    </div>
                </Row>
                <Row >

                </Row>



            </Col>

            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/getting-to-know-reader'} nextUrl={'/sensor-glucose-reading'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>

        </>


    )
}

export default SensorKit