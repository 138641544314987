import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import {useEffect, useState} from "react";

import CustomSwitcher from "../../Layout/Elements/CustomSwitcher";
import ReaderBottomButtons from "../../Layout/Elements/ReaderBottomButtons";
import {useTranslation} from "../../../Utils/UseTranslation";
import TargetsArrow from "../../Layout/Elements/TargetsArrow";

const InsulinCalc = (props) => {
    const [active, setActive] = useState();
    const [next, setNext] = useState()
    const [step, setStep] = useState(0)
    const {t} = useTranslation()
    const [logValue, setLogValue] = useState('')
    const [unit, setUnit] = useState(0);
    const [unitInitial, setUnitInitial] = useState(true)
    const [isInfo, setIsInfo] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const handleChange = (e) => {
        setLogValue(e.currentTarget.value)
    }
    return (
        <Row className={`mx-0 h-100 text-start align-items-stretch overflow-hidden`}>
            {
                step === 0 && (
                    <Col className={'mt-3'}>
                        <Form>
                            <Form.Check type={'radio'} label={t('breakfast')} value={t('breakfast')} className={'fs-5'}/>
                            <Form.Check type={'radio'} label={t('lunch')} value={t('lunch')} className={'fs-5'}/>
                            <Form.Check type={'radio'} label={t('dinner')} value={t('dinner')} className={'fs-5'}/>
                            <Form.Check type={'radio'} label={t('noMeal')} value={t('noMeal')} className={'fs-5'}/>

                        </Form>
                    </Col>
                )
            }
            {
                step === 1 && (
                    <>
                        <Col className={'mt-3'}>
                            <b> {t('suggestedDose')}</b>
                        </Col>
                        <Col md={12} className={'text-center'}>
                            <p>{t('adjustNeed')}</p>
                        </Col>
                        <Col md={12}
                             className={`align-items-start d-inline-flex mt-2 justify-content-between flex-row col-md-12`}>
                            <TargetsArrow unit={unit} setUnit={setUnit} initialValue={0}
                                          setUnitInitial={setUnitInitial} unitInitial={unitInitial}
                                          setIsChanged={setIsChanged}/>
                            <div className={'mt-2 text-end justify-content-around'}>

                                <h2 style={{fontSize: '80px'}}>  {unit === null ? '--' : unit}
                                    <span className={'fs-6'}>{t('u')}</span>
                                </h2>
                                {unit !== 0 && (
                                    <>
                                        <p>{t('userChange')}: + {unit}.0</p>
                                    </>
                                )}
                            </div>


                        </Col>

                    </>

                )
            }
            <Row className={'mx-0'}>
                <ReaderBottomButtons hideNext={step === 0 && logValue !== ''} prevOnClick={() => {
                    step === 0 ? props.navigateToComponent(props.prevStep) : setStep(step - 1)
                }} nextOnClick={() => {
                    setStep(step + 1)
                    step === 1 && props.navigateToComponent('logbook')
                }
                } backBtnText={'back'} nextBtnText={step != 1 ? 'next' : 'logDose'}/>
            </Row>
        </Row>
    )

}
export default InsulinCalc