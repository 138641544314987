import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import viewGlucose from './../../../../assets/img/Reader/homeScreen/viewGlucose.png'
import reviewHistory from './../../../../assets/img/Reader/homeScreen/reviewHistory.png'
import sensorImg from './../../../../assets/img/Reader/homeScreen/sensor.png'
import activeInsulin from './../../../../assets/img/Icons/activeInsuline.jpg'
import {useEffect, useState} from "react";
import HomeScreenHeader from "./HomeScreenHeader";

const HomeScreen = (props) => {
    // const {t, i18n} = useTranslation();
    const [activedInsulin, setActivedInsulin] = useState(false)
    const [insulinDetail, setInsulinDetail] = useState(false)
    useEffect(() => {
        const storageKeys = ['glucoseValue', 'rapidActing', 'longActing', 'food','checked', 'glucoseNumericValue'];

        const glucoseValue = localStorage.getItem('glucoseValue');
        const rapidActing = localStorage.getItem('rapidActing');
        const longActing = localStorage.getItem('longActing');
        const food = localStorage.getItem('food');
        const numericValue = localStorage.getItem('glucoseNumericValue')
        const date = localStorage.getItem('date')

        // Controlla se i valori nel localStorage non sono nulli o undefined prima di aggiungerli a props.glucoseReading
        if (!props.isStatic) {
            if (glucoseValue || rapidActing || longActing || food || numericValue) {
                props.setGlucoseReading(prevValue => [...prevValue, {
                    glucoseValue,
                    numericValue,
                    rapidActing,
                    longActing,
                    food,
                    date
                }]);
            }
        }

        // Rimuovi i valori dal localStorage solo se tutti i valori sono stati recuperati correttamente
        storageKeys.forEach(key => localStorage.removeItem(key));

        console.log(props.glucoseReading);
    }, [])
    useEffect(() => {
        console.log(props.glucoseReading)
    }, [props.glucoseReading])
    return (!activedInsulin ? (
            <Row className={'mx-0 h-100 text-start justify-content-center '}>
                <Col md={12} className={'align-items-center'}>
                    <HomeScreenHeader isStatic={props.isStatic} navigateToComponent={props.navigateToComponent} firstActivation={props.firstActivation} t={props.t}
                    sensorIsActive={props.sensorIsActive}/>


                </Col>
                <Col>
                    <Row className={'align-items-end text-center justify-content-center'}>
                        <Col md={6} className={'mt-4 align-self-center'}>
                            <Link to={''} style={{pointerEvents: props.isStatic && 'none'}} onClick={() => {
                                props.sensorIsActive ? props.navigateToComponent('viewGlucose') : props.navigateToComponent('startSensor')
                                props.sensorIsActive && props.setBehaviorIndex((prevIndex) => (prevIndex + 1) % props.behavior.length);
                            }}>
                                <img src={viewGlucose} className={'img-fluid '}/>
                                <p className={' mx-2'}>{props.sensorIsActive ? props.t('viewGlucose') :  props.t('startNewSensor')}</p>
                            </Link>
                        </Col>
                        <Col md={6}>
                            <Link to={''} style={{pointerEvents: props.isStatic && 'none'}} onClick={() => props.navigateToComponent('reviewHistory')}>
                                <img src={reviewHistory} className={'img-fluid '}
                                     onClick={() => props.navigateToComponent('reviewHistory')}/>
                                <p className={''}>  { props.t('reviewHistory')} </p>
                            </Link>
                        </Col>
                    </Row>
                </Col>
                {/*<Col md={12} className={'mb-3'}>
                    <Link to={''} style={{pointerEvents: props.isStatic && 'none'}}onClick={() => props.navigateToComponent('startSensor')}>
                        <img src={sensorImg} className={'img-fluid me-2'}
                             onClick={() => props.navigateToComponent('startNewSensor')}/>
                        <span className={'small'}>{ props.t('startNewSensor')} </span>
                    </Link>
                </Col>*/}
                <Col md={12} className={'text-end'}>
                    {props.activeInsulin &&
                        (
                            <>
                                <Link style={{pointerEvents: props.isStatic && 'none'}} onClick={() => {
                                    setActivedInsulin(true)
                                }}>
                                    <img src={activeInsulin} className={'img-fluid'}/>
                                </Link>
                            </>
                        )
                    }
                </Col>

            </Row>
        ) : !insulinDetail ? (
            <Row
                className={`mx-0 h-100 text-start overflow-hidden`}>
                <Col md={8} className={'align-items-center'}>

                </Col>
                <Col md={4} className={'text-end px-0 position-relative'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                        setActivedInsulin(false)
                    }}>OK</Button>
                </Col>
                <Col>
                    <p className={'text-center'}>
                        <img src={activeInsulin} className={'img-fluid'}/>
                    </p>
                    <p><span>{props.t("activeInsulin")}:</span> <span>2 {props.t("units")}</span></p>
                    <p><span>{props.t("timeLeft")}:</span><span> 3:24</span></p>


                </Col>
                <Col md={12} className={'align-self-end'}>
                    <p className={'text-center'}><Button className={'btn btn-secondary w-100'} onClick={() =>{
                    setInsulinDetail(true)
                }}>{props.t('viewDetail')}</Button>
                </p>
                </Col>

            </Row>
        ) : (
            <Row
                className={`mx-0 h-100 text-start overflow-hidden`}>
                <Col md={8} className={'align-items-center'}>
                    <b>{props.t('activeInsDetail')}</b>
                </Col>
                <Col md={4} className={'text-end px-0 position-relative'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                        setInsulinDetail(false)
                    }}>OK</Button>
                </Col>
                <Col>

                    <p>{props.t('activeInsDetail_text')}</p>
                </Col>
            </Row>
        )

        )
}

export default HomeScreen