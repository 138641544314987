import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";

const ReaderNav = (props) => {
    return (
        <Row className={'bg-white shadow-lg mx-0 text-black p-2 text-start subMenu px-0'}>
            <Col md={12}>
                {/*<p className={'fw-bold mb-0 pb-2 text-blue'}>{props.t('reader')}</p>*/}
                <ul className={'list-unstyled text-start px-0'}>
                    <li className={'p-2 '}>
                        <Link to={'/first-time-reader-setup'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('firstTimeReader')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/reader-homescreen'} onClick={props.handleClickInner}  className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('readerHomeScreen')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/setting-alarms'} onClick={props.handleClickInner}  className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('settingAlarms')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/setting-reminders'} onClick={props.handleClickInner}  className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('settingReminders')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/change-reader-settings'} onClick={props.handleClickInner}  className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('changingReaderSettings')}
                          {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/professional-options'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('accessingHcp')}
                            {/*  <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                </ul>
                {/*<p className={'fw-bold mb-0 pb-2'}>{props.t('professionalOptions')}</p>*/}
              {/*  <ul className={'list-unstyled text-start'}>
                    <li className={'p-2 '}>
                        <Link to={'/professional-options'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('accessingHcp')}
                            <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>
                        </Link>
                    </li>
                </ul>*/}
            </Col>
          {/*  <Col md={12} >

            </Col>*/}
        </Row>
    )
}
export default ReaderNav