import React, {useEffect, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";


import {useTranslation} from "react-i18next";
import {FiArrowDown, FiArrowDownRight, FiArrowRight, FiArrowUpRight, FiArrowUp} from "react-icons/fi";
import {BiSolidRightArrow} from "react-icons/bi";
import {BiSolidLeftArrow} from "react-icons/bi";


const Graphs = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(new Date());

    const [average, setAverage] = useState('dynamicValues.average.7')
    const [totalEvents, setTotalEvents] = useState('dynamicValues.totalEvents.7')
    const [sensorUsage, setSensorUsage] = useState({
        views: 'dynamicValues.sensorUsage.views.7',
        time: 'dynamicValues.sensorUsage.time.7'
    })
    const [currentIndex, setCurrentIndex] = useState(0);
    const [period, setPeriod] = useState(['7', '14', '30', '90'])
    useEffect(() => {

    }, [average, currentIndex])

    /*Se vengo da review History, setto la data ad un giorno prima per far apparire entrambe le frecce, come da richiesta*/
    useEffect(() => {
        const nextDate = new Date(today);
        if (props.isFromReviewHistory) {
            nextDate.setDate(nextDate.getDate() - 1); // Aggiorna la data corrente
            console.log(today)
            setSelectedDate(nextDate);

        }
    }, [props.isFromReviewHistory])
    const goToNext = () => {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + 1); // Incrementa la data di un giorno
        console.log(image)
        if (nextDate <= new Date()) {
            today.setDate(today.getDate() + 1); // Aggiorna la data corrente
            setCurrentIndex(currentIndex + 1);
        }
        if (currentIndex < period.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setAverage('dynamicValues.average.' + period[currentIndex + 1])
            setTotalEvents('dynamicValues.totalEvents.' + period[currentIndex + 1])
            setSensorUsage({
                views: 'dynamicValues.sensorUsage.views.' + period[currentIndex + 1],
                time: 'dynamicValues.sensorUsage.time.' + period[currentIndex + 1]
            })
        }
    };

    const goToPrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setAverage('dynamicValues.average.' + period[currentIndex - 1])
            setTotalEvents('dynamicValues.totalEvents.' + period[currentIndex - 1])
        }
    };


    useEffect(() => {
        setImage(`/translations/${lang}/graphs/${props.grapType}${period[currentIndex]}.jpg`)

    }, [lang, currentIndex, period])


    const formatDate = (date) => {
        return {
            dayOfWeek: date.toLocaleDateString('en-GB', {weekday: 'long'}),
            dayOfMonth: date.toLocaleDateString('en-GB', {day: '2-digit', month: 'short'})
        };
    };

    const nextDay = () => {
        const nextDate = new Date(selectedDate);
        nextDate.setDate(selectedDate.getDate() + 1);
        if (nextDate <= new Date()) {
            setSelectedDate(nextDate);
            const newIndex = (currentIndex - 1 + period.length) % period.length;
            setCurrentIndex(newIndex);
        }


    };

    const previousDay = () => {
        const previousDate = new Date(selectedDate);
        previousDate.setDate(selectedDate.getDate() - 1);
        setSelectedDate(previousDate);
        const newIndex = (currentIndex + 1 + period.length) % period.length;
        setCurrentIndex(newIndex);
        console.log(image)
    };

    const previousDays = (numDays) => {
        const previousDate = new Date(selectedDate);
        previousDate.setDate(selectedDate.getDate() - numDays);
        setSelectedDate(previousDate);
    };

    const {dayOfWeek, dayOfMonth} = formatDate(selectedDate);

    return (
        (props.firstActivation && !props.sensorIsActive) ? (
            <Row
                className={`mx-0 h-100 text-start overflow-hidden align-content-start`}>
                <Col md={8} className={'align-items-center'}>
                    <b> {t('noSensorData')}</b>
                </Col>
                <Col md={4} className={'text-end px-0 position-relative'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                        props.navigateToComponent('reviewHistory')
                    }}>OK</Button>
                </Col>

                <Col md={12}
                     className={`align-content-start d-inline-flex flex-column  mt-5`}>
                    <p>{t('logbookNoData')}</p>


                </Col>
            </Row>
        ) : (

            <Row
                className={`mx-0 h-100 text-start justify-content-start overflow-hidden ${props.isStatic ? props.grapType !== 'sensorUsage' ? ' align-content-start' : 'align-content-between' : ''}`}
                style={{minHeight: props.isStatic ? (props.grapType === 'sensorUsage' ? ' 250px' : '250px') : ''}}>


                <Col md={9} className={'align-items-center py-2'}>
                    <b> {t(props.grapType)}
                        <br/>
                        {props.grapType === 'dailyGraph' && t('dynamicValues.unit')}
                    </b>
                </Col>
                <Col md={3} className={'text-end px-0 position-relative'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{
                                top: '0',
                                right: '-10px',
                                borderRadius: '0 0 0 30px',
                                pointerEvents: props.isStatic && 'none'
                            }} onClick={() => {
                        props.navigateToComponent('reviewHistory')
                    }}>OK</Button>
                </Col>
                <Col className={'text-center'}>
                    {props.grapType === 'averageGlucose' && (
                        <span>{t("average")}: <span
                            className={'fw-bold fs-4'}> {t(average)}</span> {t("dynamicValues.unit")}</span>

                    )}
                    {props.grapType === 'lowGlucoseEvents' && (
                        <span>{t("totalEvents")}: <span
                            className={'fw-bold fs-4'}> {t(totalEvents)}</span> </span>

                    )}
                </Col>
                <Col md={12} className={'align-items-center px-0 text-center position-relative'}>
                    {props.grapType !== 'sensorUsage' ? (

                        <>{props.foodIcon && <img src={'/icons/food.png'} className={'img-fluid position-absolute'} style={{right: '65px', top: '20px'}}/>}
                            <img src={image} className={'img-fluid'}/></>) : (
                        <>
                            <p className={'d-inline-flex'}><span className={'small me-2'}>{t('viewsPerDay')}</span>
                                <span
                                    className={'fs-3 fw-bold'}>{t(sensorUsage.views)}</span></p><br/>
                            <p className={'d-inline-flex'}><span className={'small me-2'}>{t('timeSensorActive')}</span>
                                <span className={'fs-3 fw-bold'}>{t(sensorUsage.time)}</span></p>


                        </>
                    )}

                    {props.grapType === 'timeInTarget' && (
                        <span className={'small'}>{t("targetRange")}
                            <br/> {t("dynamicValues.targetRange")} {t("dynamicValues.unit")}</span>

                    )}
                </Col>
                {props.grapType && (
                    (props.grapType === 'dailyGraph') ? (
                        <Col md={12} className={'text-center small'}>
                            <Row className={'align-items-center'}>
                                <Col> <span onClick={previousDay}
                                            disabled={currentIndex === period.length - 1}
                                            style={{pointerEvents: props.isStatic && 'none'}}><BiSolidLeftArrow style={{
                                    fontSize: '30px',
                                    color: 'var(--light-grey-2)',
                                    filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                }}/>
                            </span></Col>
                                <Col className={'text-center small'}><b>{dayOfMonth}</b> <br/> {dayOfWeek}

                                </Col>

                                <Col>
                                    {new Date().toLocaleDateString() !== selectedDate.toLocaleDateString() && (
                                        <span onClick={nextDay}
                                              style={{pointerEvents: props.isStatic && 'none'}}><BiSolidRightArrow
                                            style={{
                                                fontSize: '30px',
                                                color: 'var(--light-grey-2)',
                                                filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                            }}/></span>
                                    )}
                                </Col>

                            </Row>
                        </Col>

                    ) : (
                        <Col md={12} className={'text-center small'}>
                            <Row className={'align-items-center'}>
                                 <Col> <span onClick={!props.isStatic && goToNext}
                                            disabled={currentIndex === period.length - 1}
                                            style={{pointerEvents: props.isStatic && 'none'}}><BiSolidLeftArrow style={{
                                    fontSize: '30px',
                                    color: 'var(--light-grey-2)',
                                    filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                }}/>
                            </span></Col>
                                <Col md={6} className={'small'}>
                                    <b>{t("last")} {period[currentIndex]} {t("days")}</b></Col>
                               <Col> {period[currentIndex] > 7 &&      <span  onClick={goToPrevious} style={{pointerEvents: props.isStatic && 'none'}}
                                                                         ><BiSolidRightArrow style={{
                                    fontSize: '30px',
                                    color: 'var(--light-grey-2)',
                                    filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                }}/></span> }
                               </Col>

                            </Row>

                        </Col>
                    )
                )}


            </Row>
        )
    );
}

export default Graphs