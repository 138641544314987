// EmailPasswordLogin.js
import React, {useContext, useState} from 'react';
import {auth, signInWithEmailAndPassword} from '../../Config/FirebaseConfig';
import AuthContext from "../../Context/AuthContext";
import {Alert, Container, Form, Button, Card, Row, Col} from "react-bootstrap";
import fsl2LogoBlack from '../../assets/img/logo_white_butterfly.png'

const EmailPasswordLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    const {login} = useContext(AuthContext)

    //handle login with identity provider google
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
                login(JSON.stringify({
                    uid: userCredential.user.uid,
                    email: userCredential.user.email
                }), userCredential.user.accessToken, userCredential.user.stsTokenManager.expirationTime + (24 * 60 * 60 * 1000))
            });
        } catch (error) {
            console.log(error.message)
            setError('Login Failed, check email and password');
        }
    };

    return (
        <Container className="mt-5">
            <Row className={'  h-100 justify-content-center text-center'}>
       {/*     <Row>
                <Col>
                    <img src={fsl2Logo}/>
                </Col>
            </Row>*/}
          <Col className={'md-12'}>

          </Col>
            <Col md={12}>
                <Card style={{width: '24rem'}} className="p-4 bkg-gradient m-auto">
                    <img src={fsl2LogoBlack} className={'img-fluid'}  style={{width: '300px'}}/>
                    <Form>

                        {/*<img src={abbotLogo} className={'img-fluid'} style={{width: '150px'}}/>*/}
                        <Form.Group controlId="formBasicEmail" style={{marginTop: 10}}>
                            <Form.Label className={'fw-bold'}>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" style={{marginTop: 10}}>
                            <Form.Label className={'fw-bold'}>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button className={'bkg-primary text-white border-0 py-3 rounded-3 w-100'} style={{marginTop: 30}}
                                variant="primary" type="button" onClick={handleLogin}>
                            Login
                        </Button>

                        {error && <Alert style={{marginTop: 30}} variant="danger">{error}</Alert>}
                    </Form>
                </Card>
            </Col>
            </Row>
        </Container>
    );
};

export default EmailPasswordLogin;
