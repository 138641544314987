import {useState} from "react";
import {useTranslation} from "../../Utils/UseTranslation";
import {Col, Form} from "react-bootstrap";
import {HiExclamationTriangle} from "react-icons/hi2";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import ModalNote from "../../Components/Modal/ModalNote";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";
import {Link, useOutletContext} from "react-router-dom";
import {CiCircleInfo} from "react-icons/ci";


const UsingGlucoseAlarmsHiLo = (props) => {
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [turnOnOff, setTurnOnOff] = useState(true)
    const [homeBtnBehavior, setHomeBtnBehavior] = useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const [fromSettingAlarms, setFromSettingAlarms] = useState(true)
    const [alarmSound, setAlarmSound] = useOutletContext()
    const handleClose = () => {
        setShow(false)
    }

    const {t} = useTranslation()

    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("usingHiLoGlucose")}</h2>
                {/*<p>{t('infoList4_text_2', { measureUnit: i18next.t('dynamicValues.unita_misura')})}</p>*/}
                <p className={'text-orange fw-bold mb-0 mt-2'}> {t("howTo")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-inline-flex align-items-center justify-content-between'}>
                   <span> {t("settingAlarmsText")} </span><span>
                        <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('caution'))
                            setText(<ul>
                                <li>
                                    {t('usingHiLo_caution_1')}
                                </li>
                                <li>
                                    {t('usingHiLo_caution_2')}
                                </li>

                            </ul>)
                        }}/>
                    </span></p>
                <hr className={'text-orange my-1 opacity-100'}/>

                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("usingHiLo_List2")}  </span><span><CiCircleInfo
                    className={'fs-3 text-end text-orange'} onClick={() => {
                    setShow(true)
                    setTitle(t('important'))
                    setText(<ul>
                        <li>
                            {t('usingHiLo_info_1')}
                        </li>
                        <li>
                            {t('usingHiLo_info_2')}
                        </li>
                        <li>
                            {t('usingHiLo_info_3')}
                        </li>

                    </ul>)
                }}/></span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0'}>{t("usingHiLo_List3")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("usingHiLo_List4")}</span>

                </p>

                <p className={'text-smaller mb-0 mt-4 fw-bold'}>{t('notes')}</p>
                <ul style={{lineHeight: '1em'}}>
                    <li className={'text-smaller'}>
                        {t('usingHilo_notes_1')}
                    </li>
                    <li className={'text-smaller'}>
                        {t('usingHilo_notes_2')}
                    </li>
                </ul>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 fw-bold'}>{t('selectOptions')}</p>
                <Form.Check
                    className={'text-orange fw-bold'}
                    name={'alarmSound'}
                    type={'radio'}
                    value={'low'}
                    label={t("glucoseBelow")}
                    onChange={() => {
                        setAlarmSound('low')
                    }}
                />
                <Form.Check className={'text-orange fw-bold'}
                            name={'alarmSound'}
                            type={'radio'}
                            value={'high'}
                            label={t("glucoseAbove")}
                            onChange={() => {
                                setAlarmSound('high')
                            }}
                />


                <span className={'text-smaller'}>{t('note')}</span>
                <p className={'text-smaller'}>{t('usingHilo_notes_alarms')}</p>

            </Col>
            <Col md={6} className={'align-self-center'}>
                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior}
                             setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff &&
                        <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep}
                                                  setCurrentStep={setCurrentStep}
                                                  fromSettingAlarms={fromSettingAlarms} sensorIsAlreadyActive={true}
                                                  firstActivation={false} isFromUsingHiLo={true}/>}

                </ReaderEmpty>

            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/understanding-readings'} nextUrl={'/using-signalLoss-alarms'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>

    )
}

export default UsingGlucoseAlarmsHiLo