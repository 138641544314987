import {Col, Row} from "react-bootstrap";

import {useTranslation} from "../../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import './../../../assets/css/ProductUse.css'
import GlucoseReadings from "../../Reader/FirstTimeSetup/HomeScreens/GlucoseReadings";

const AfterExercise = () => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState([]);
    const [imageApp, setImageApp] = useState([]);
    const [behaviorIndex, setBehaviorIndex] = useState(0);
    const behavior = ['reader_204']
    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/treatmentGuide/reader_204.png`)
        setImageApp(`/translations/${lang}/ProductUse/treatmentGuide/app_204.png`)
    }, [lang])
    useEffect(() => {
        console.log(image)
    }, [image])

    return (

        <Row>
            <Col md={4} className={'text-start '}>
                <p className={'p-2'}>
                    <b>{t("whatYouSee")}: </b> {t("afterExercise_text")}
                </p>
                <p className={'p-2 treatmentColoured'}>
                    <b>{t("whatMeans")}: </b> {t('afterExercise_text_2')}

                </p>

            </Col>
            <Col md={8} className={'align-items-center justify-content-center d-inline-flex  px-5'}>
                <div className={'text-center'}>
                    <p className={'mx-0'}>
                        <b> {t('reader')}</b>
                    </p>
                    <div style={{width: '230px'}}>
                        <GlucoseReadings navigateToComponent={''} setFirstActivation={''}
                                         sensorIsActive={true} setSensorIsActive={''}
                                         firstActivation={false} behaviorIndex={behaviorIndex}
                                         behavior={behavior} isStatic={true}/></div>

                </div>


            </Col>


           {/* <Col className={'text-start'}>
                <NavigationButtons t={t} prevUrl={'/replacing-sensor'} nextUrl={'/understanding-readings'}/>
            </Col>*/}
        </Row>

    )
}

export default AfterExercise