import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";

const ProductUseNav = (props) => {
    return (
        <Row className={'bg-white shadow mx-0 p-2 text-start subMenu px-0'}>
           {/* <p className={'fw-bold mb-0 pb-2 text-blue'}>
                {props.t('productUse')}
            </p>*/}
            <Col md={12}>

                <ul className={'list-unstyled text-start px-0 mb-0'}>
                    <li className={'p-2 '}>
                        <Link to={'/checking-glucose'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('checkingWSensor')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/understanding-readings'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('understandingSensor')} - {props.t('reader')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/using-glucose-alarms'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('usingHiLoGlucose')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/using-signalLoss-alarms'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('usingSignalLossAlarm')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>

                </ul>
            </Col>
            <Col>
                <ul className={'list-unstyled text-start'}>

                    <li className={'p-2 '}>
                        <Link to={'/understanding-alarms'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('understandingAlarms')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/checking-with-strip'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('checkingWithTestStrip')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/adding-notes'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('addingNotes')}
                     {/*      <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/reviewing-history'} onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('reviewingHistory')}
                           {/* <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                </ul>
            </Col>

        </Row>
    )
}
export default ProductUseNav