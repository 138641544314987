import {useTranslation} from "../../Utils/UseTranslation";
import {Col, Row} from "react-bootstrap";
import './../../assets/css/ProductOverview/GettingToKnow.css'
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {useEffect, useState} from "react";

const GettingToKnow = () => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(`/translations/${lang}/readerFsl2.png`)
    }, [lang])
    // Array di oggetti testo con le informazioni per il posizionamento
    const textData = [
        {id: 1, text: t("touch"), x: "80%", y: "22%"},
        {id: 2, text: t("usbPort"), text2: t('usbPortText'), x: "6%", y: "64%", right: "68%"},
        {id: 3, text: t("testStripPort"), text2: t('testStripPortText'), x: "6%", y: "85.5%", right: "60%"},
        {id: 4, text: t("homeBtn"), text2: t('homeBtnText'), x: "60%", y: "58%", right: '5%', class: 'text-end d-inline-flex flex-row align-items-end flex-column'},

    ];
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("gettingToKnowTitle")}</h2>
                <p>{t("gettingToKnowText_1")}</p>
                <p> {t("gettingToKnowText_2")}</p>
               {/* <p className={'mb-0'}> {t("important")} :</p>
                <ul>
                    <li>{t("gettingToKnowList_1")}</li>
                    <li>{t("gettingToKnowList_2")}</li>
                </ul>*/}

            </Col>
            <Col md={6} className={'text-start'}>
                <Row className={'image-container gettingToknowImg'}>


                    <img src={image} className={'img-fluid'}/>
                    <div>  {textData.map(item => (
                        <div
                            key={item.id}
                            className={`text-overlay ${item.class}`}
                            style={{left: item.x, top: item.y, right: item.right && item.right}}
                        >
                            <p className="text mb-1">{item.text}</p>
                            <span className="small secondaryText">{item.text2}</span>
                        </div>
                    ))}
                    </div>
                </Row>

            </Col>
<Col md={12} className={'text-start align-self-end'}> <NavigationButtons t={t} prevUrl={'/system-overview'} nextUrl={'/sensor-kit'}/></Col>
        </>


    )
}

export default GettingToKnow