import {Col, Row, Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";
import chargeReader from './../../assets/img/ProductUse/ChargingReader/reader_connect_cable.png'

import {useEffect, useState} from "react";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";
import WakeUp from "../../Components/ProductUse/TreatmentGuide/WakeUp";
import BeforeBreakfast from "../../Components/ProductUse/TreatmentGuide/BeforeBreakfast";
import Lunch from "../../Components/ProductUse/TreatmentGuide/Lunch";
import InAfternoon from "../../Components/ProductUse/TreatmentGuide/InAfternoon";
import AfterExercise from "../../Components/ProductUse/TreatmentGuide/AfterExercise";
import BeforeDinner from "../../Components/ProductUse/TreatmentGuide/BeforeDinner";
import imgStep3 from "../../assets/img/ProductUse/ControlSolutionTesting/step_3.jpg";
import imgStep4 from "../../assets/img/ProductUse/ControlSolutionTesting/step_4.jpg";

const Maintenance = () => {
    const {t, i18n} = useTranslation();
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [imageStep1, setImageStep1] = useState()
    const readerSettingInfo_List_1 = parse(t("readerSettingInfo_List_1").replace('%SETTINGS_IMG%', '<img src="/icons/settings.png" /> '))
    const lang = localStorage.getItem('lang');

    const controlSolutionsInfoTXT = []
    for (let i = 1; i < 10; i++) {
        controlSolutionsInfoTXT.push('controlSolutionTesting_Info_' + i)
    }
    useEffect(() => {
        setImageStep1(`/translations/${lang}/ProductUse/ControlSolutionTesting/step_1.png`)
    }, [lang])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={12} className={'align-content-start text-start'}>
                <h2>{t("maintenanceDisposal")}</h2>
                <Tabs
                    defaultActiveKey="cleaningReader"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill

                >
                    <Tab eventKey="cleaningReader" title={t("cleaningReader")}>
                        <p className={'mb-2'}>{t("cleaningReader_Text")} </p>
                        <p className={'mb-2'}>{t("cleaningReader_Text_1")} </p>
                        <p className={'mb-2'}>{t("cleaningReader_Text_2")} </p>
                        <p className={'mb-2'}>{t("cleaningReader_Text_3")} </p>
                        <p className={'mb-2'}>{t("cleaningReader_Text_4")} </p>
                        <p className={'mb-2'}>{t("cleaningReader_Text_5")} </p>
                        <p className={'mb-2'}>{t("cleaningReader_Text_6")} </p>
                        <p className={'mb-2'}><b>{t("note")}: </b>{t("cleaningReader_Text_7")} </p>

                    </Tab>
                    <Tab eventKey="howTo" title={t("howTo")}>
                        <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                            <span className={'h2 me-4'}>1</span> <span>{t("cleaningReader_Step_1")} </span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                            <span className={'h2 me-4'}>2</span>
                            <span>{t("cleaningReader_Step_2")} </span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                            <span className={'h2 me-4'}>3</span>
                            <span>{t("cleaningReader_Step_3")}</span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                            <span className={'h2 me-4'}>4</span>
                            <span>{t("cleaningReader_Step_4")}</span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                            <span className={'h2 me-4'}>5</span>
                            <span>{t("cleaningReader_Step_5")}</span>
                        </p>
                        <p className={'mt-2'}><b>{t("important")}</b>: {t("cleaningReader_Step_Important")}: </p>
                    </Tab>
                    <Tab eventKey="maintenance" title={t("maintenance")}>
                    <Row className={'mx-0'}>
                        <p>{t("cleaningReader_Maintenance")}</p>
                    </Row>
                    </Tab>
                    <Tab eventKey="disposal" title={t("disposal")}>
                       <Row className={'mx-0'}>
                           <p>{t("cleaningReader_Disposal")}</p>
                       </Row>
                    </Tab>

                </Tabs>





                <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>

            </Col>
            <Col className={'align-self-end text-start'}> <NavigationButtons t={t} prevUrl={'/reader-settings-info'}
                                                                             nextUrl={'/adding-notes'}/></Col>
        </>
    )
}

export default Maintenance