import {useEffect, useRef} from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const VideoPlayer = (props) => {
    const videoRef = props.videoRef
    const t =props.t

    useEffect(() => {
        const videoElement = videoRef && videoRef.current;
        if (videoElement) {
            const handlePlay = () => {
                console.log('Video started playing');
                props.actionOnStart && props.actionOnStart()
            };

            const handleEnded = () => {
                console.log('Video ended');
                setTimeout(function () {
                    props.actionOnEnd && props.actionOnEnd()
                }, 500)
            };

            // Aggiungere gli event listener
            videoElement.addEventListener('play', handlePlay);
            videoElement.addEventListener('ended', handleEnded);

            // Pulire gli event listener quando il componente si smonta
            return () => {
                videoElement.removeEventListener('play', handlePlay);
                videoElement.removeEventListener('ended', handleEnded);
            };
        }
    }, []);
    const playVideo = () => {
        setTimeout(function () {
            if (videoRef.current) {
                videoRef.current.play();

            }
        }, 500)

    };
    return (
        <>
            <video ref={videoRef} height={'auto'} width={'100%'} controls={true} poster={props.poster && props.poster} onEnded={props.onEnded && props.onEnded}>
                <source src={props.video} type="video/mp4"/>
                Il tuo browser non supporta il tag video.
            </video>
            <Row className={'justify-content-end text-end px-4'}>
                <Col md={2} className={'text-end '}>
                    <Col md={2} className={'text-end'}>
                        <Row> <
                            Link onClick={playVideo} className={'btn bkg-primary btnNav '}> {t('play')}</Link>
                        </Row>
                        {props.startingSensor && <Row className={'mt-2'}>
                            <Link onClick={props.handleShowReader}
                                  className={'btn bkg-primary btnNav'}> {t('reader')}</Link>
                        </Row>
                        }
                    </Col>
                </Col>

            </Row>

        </>
    )
}

export default VideoPlayer