import {Button, Col, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import ReaderBottomButtons from "../../../../../Layout/Elements/ReaderBottomButtons";

const ProfessionalOptions = (props) => {
    const {t} = useTranslation()
    const [isFromProfessional, setIsFromProfessional] = useState(props.isFromProfessional)
    const [hideNext, setHideNext] = useState(true)
    const [step, setStep] = useState(0)
    const [isProfessionalUser, setIsProfessionalUser] = useState()
    const [code, setCode] = useState('')
    useEffect(() => {
        setStep(1)
    }, [])

    useEffect(() => {
        console.log(hideNext)
    }, [hideNext])

    const healthCareChange = (e) => {
        console.log(e.currentTarget.value)
        setHideNext(false)
        setIsProfessionalUser(e.currentTarget.value)
    }

    useEffect(() => {
        console.log(code)
    }, [code])
    const handleCodeChange = (e) => {
        if (code === 'incorrect') {
            setCode('')
        }
        const char = e.target.textContent.trim(); // Rimuovi eventuali spazi prima e dopo il testo
        setCode(prevState => prevState + char); // Aggiungi il carattere alla stringa code
    }
    return (
        <>
            <Row
                className={`mx-0 h-100 overflow-hidden ${(isFromProfessional && step !== 0) ? 'align-content-between' : 'align-content-start'} `}>
                {isFromProfessional && step === 'fromProfessional' ? (
                        <>
                            <Col md={8} className={'align-items-center'}>
                                <p className={'text-start'}> {t('hcpTitle')}</p>
                            </Col>
                            <Col md={12} className={'custom-checkbox"'}>

                                <Form.Check onChange={healthCareChange} value={0} label={t("yes")} type="radio"
                                            name="healtCare"></Form.Check>
                                <Form.Check onChange={healthCareChange} value={1} label={t("no")} type="radio"
                                            name="healtCare"></Form.Check>
                            </Col>
                            <ReaderBottomButtons hideNext={hideNext} prevOnClick={() => {
                                props.navigateToComponent('settings')
                            }} nextOnClick={() => {
                                isProfessionalUser == 0 ? setStep(1) : setStep(2)
                            }
                            } backBtnText={'back'} nextBtnText={'next'}/>
                        </>
                    ) :

                    step === 0 &&
                    (
                        <>
                            <Col md={8} className={'align-items-center px-0 align-items-start text-start ps-2'}>
                                <b>  {t("professionalOptions")}</b>
                            </Col>

                            <Col md={4} className={'text-end px-0 position-relative px-0'}>
                                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                        onClick={() => {
                                            props.navigateToComponent('settings')
                                        }}>{t("ok")}</Button>
                            </Col>
                            <Row className={'align-items-center mx-0 mt-3'}>
                                <Col md={12} className={'px-1'}>
                                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                          onClick={() => {
                                              props.navigateToComponent('doseIncrement')
                                          }}>
                                   <span
                                       className={'small'}>{t("doseIncrement")}</span></Link>

                                </Col>

                            </Row>
                            <Row className={'align-items-center mx-0  mt-3'}>
                                <Col md={12} className={'px-1'}>
                                    <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                          onClick={() => {
                                              props.setCalculatorOff(false)
                                              props.navigateToComponent('insulinCalculator')

                                          }}>
                                    <span
                                        className={'small'}>{t("insulinCalculator")}</span></Link>
                                </Col>


                            </Row>
                        </>
                    )
                }
                {
                    step === 1 && (
                        <>
                            <Row className={'mx-0 h-100 align-items-between justify-content-stretch'}>
                                <Col md={12} className={'mt-2 px-0'}>
                                    <Form.Control type={'password'} placeholder={code === 'incorrect'  ? t('incorrectCode') : t('enterCode')} defaultValue={code !== 'incorrect' ? code : ''}
                                                  maxLength={6}
                                                  onChange={(e) => {
                                                      const inputValue = e.target.value.replace(/\s/g, ''); // Rimuovi gli spazi utilizzando una regex
                                                      setCode(inputValue.split(''));
                                                  }}
                                    ></Form.Control>
                                </Col>
                                <Col md={5}
                                     className={'bkg-ligth-grey border-groove m-2 d-flex align-items-center justify-content-center'}
                                     onClick={handleCodeChange}>
                                    <h5 className={'fs-2 mb-0 text-center'}> A </h5>
                                </Col>
                                <Col md={5}
                                     className={'bkg-ligth-grey border-groove m-2 d-flex align-items-center justify-content-center'}
                                     onClick={handleCodeChange}
                                >
                                    <h5 className={'fs-2 mb-0 text-center'}> 1 </h5>
                                </Col>
                                <Col md={5}
                                     className={'bkg-ligth-grey border-groove m-2 d-flex align-items-center justify-content-center'}
                                     onClick={handleCodeChange}

                                >

                                    <h5 className={'fs-2 mb-0 text-center'}> B </h5>
                                </Col>
                                <Col md={5}
                                     className={'bkg-ligth-grey border-groove m-2 d-flex align-items-center justify-content-center'}
                                     onClick={handleCodeChange}
                                >
                                    <h5 className={'fs-2 mb-0 text-center'}> 2 </h5>
                                </Col>
                                <Col md={5}
                                     className={'bkg-ligth-grey border-groove m-2 d-flex align-items-center justify-content-center'}
                                     onClick={handleCodeChange}
                                >
                                    <h5 className={'fs-2 mb-0 text-center'}> C </h5>
                                </Col>
                                <Col md={5}
                                     className={'bkg-ligth-grey border-groove m-2 d-flex align-items-center justify-content-center'}
                                     onClick={(e) => {
                                         const char = e.target.textContent.trim(); // Rimuovi eventuali spazi prima e dopo il testo
                                         setCode(prevState => prevState + char); // Aggiungi il carattere alla stringa code
                                     }}>
                                    <h5 className={'fs-2 mb-0 text-center'}> 3 </h5>
                                </Col>
                                <ReaderBottomButtons hideBack={true} nextOnClick={() => {
                                     setStep(0)
                                }
                                } backBtnText={'back'} nextBtnText={'done'}/>
                            </Row>
                        </>
                    )
                }
                {
                    step === 2 && (
                        <>
                            <p className={'text-start'}>
                                {t('professional_Options')}
                            </p>
                            <ReaderBottomButtons hideBack={true} nextOnClick={() => {
                                props.navigateToComponent('settings')
                            }
                            } backBtnText={'back'} nextBtnText={'done'}/>
                        </>
                    )
                }
            </Row>
        </>
    )
}
export default ProfessionalOptions