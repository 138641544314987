import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "../../../Utils/UseTranslation";

const UnderstandingLoss = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);
    const sensorReadingNote_2 = t('sensorReadingNote_2', {
        settingsImageToken: '%TIME_IMG%',
        settingsImageValue: '<img src="/icons/reader_time.png" />'
    });


    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/understanding-Loss.png`)
    }, [lang])
    return (
        <>
            <Row>
                <Col md={4}>

                    <p className={'fw-bold text-start'}>{t('whatYouSee')}</p>
                    <img src={image} className={'img-fluid'}/>
                </Col>

                <Col md={7} sm={8} className={'position-relative text-start align-self-center '}>
                   <Row className={'treatmentColoured mx-0 py-2 mb-2'}>
                       <p className={'fw-bold mb-0'}>{t('whatMeans')}: </p>
                       <p>{t('understandingLoss_text')}</p>
                   </Row>
                    <b>{t('note')}: </b>
                    <ul>
                        <li>
                            {t('understandingLow_note_1')}
                        </li>
                        <li>
                            {t('understandingLow_note_2')}
                        </li>
                    </ul>

                </Col>
            </Row>


        </>
    )
}
export default UnderstandingLoss