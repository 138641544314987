import {Col, Row} from "react-bootstrap";

import systemMgDL from "../../assets/img/ProductOverview/SystemOverview/systemOverview_mg-dL.png";
import sensorMillimol from "../../assets/img/ProductOverview/SystemOverview/systemOverview-mmol-L.png"
import {useTranslation} from "../../Utils/UseTranslation";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import parse from "html-react-parser";

const SystemOverview = () => {
    const {t, i18n} = useTranslation()
    const parsedSystemOverviewList_4 =  t('systemOverviewList_4', {
        settingsImageToken: '%BLOOD_IMAGE%',
        settingsImageValue: '/icons/icona-sangue.png'
    });
    const image = t('dynamicValues.unit') === 'mg/dL' ? systemMgDL : sensorMillimol

    return (
        <>
            <Col md={5} className={'text-start ps-4'}>

                <h2> {t("systemOverviewTitle")}</h2>
                <p>{t("systemOverviewText_1")}</p>

                <p className={'mb-0'}>{t("important")} : {t("systemOverviewText_2")}</p>
                {/*<ul>
                    <li>{t("systemOverviewList_1")}</li>
                    <li>{t("systemOverviewList_2")}</li>
                    <li>{t("systemOverviewList_3")}</li>
                    <li> {parsedSystemOverviewList_4}</li>


                </ul>*/}

            </Col>
            <Col md={7} className={'align-self-center'}>


                <Row className={'align-self-center'}>
                  <Col className={'align-self-end '}>
                    <img src={image} className={'img-fluid w-100'}/>


                </Col>

                </Row>
            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/home'} nextUrl={'/getting-to-know-reader'}/>
            </Col>


        </>


    )
}

export default SystemOverview