import {useTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";
import appIcon from "../../assets/img/Icons/appIcon.png";
import alarmsUnavailable from "../../assets/img/Icons/alarmsUnavailable.png";
import scanSensor from "../../assets/img/Icons/scanSensor.png";
import directionGlucose from "../../assets/img/Icons/directionGlucoseApp.png";
import caution from "../../assets/img/Icons/cautionApp.png";
import bloodIcon from "../../assets/img/Icons/bloodIcon.png";
import addEditNotes from "../../assets/img/Icons/addNotesApp.png";
import foodNote from "../../assets/img/Icons/foodNote.png";
import insulinNote from "../../assets/img/Icons/insulinApp.png";

const ReaderSpecifications = () => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');

    return (
        <>
            <Row>

                <Col md={12} className={'text-start justify-content-start ps-4 align-self-start'}>
                    <p className={'d-flex justify-content-between fw-bold text-light-grey'}>
                        <span style={{width: '300px'}}>
                            {t("parameter")}
                        </span>

                        <span style={{width: '150px'}}>
                            {t("unit")}
                        </span>
                        <span style={{width: '150px'}}>
                            {t("rangeOf")}
                        </span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 d-block '}/>
                    <p className={'d-flex justify-content-between'}>
                            <span style={{width: '300px'}}
                                  className={'fw-bold'}> {t("correctionTarget")}</span>
                        <span style={{width: '150px'}}> {t("dynamicValues.unit")}</span>
                        <span style={{width: '150px'}}> {t("correctionTargetRange")}</span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>
                        <span style={{width: '300px'}}
                              className={'fw-bold'}> {t("carbohydrateRatio")}</span>
                        <span style={{width: '150px'}}>1 {t("unitFor_long")} X {t('gramsOf')}</span>
                        <span style={{width: '150px'}}>{t("rangeOfCarbs")}</span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>
                            <span style={{width: '300px'}}
                                  className={'fw-bold'}> {lang === 'en' ? t('carbPortionRatio') : t("servingsRatio")}</span>
                        <span style={{width: '150px'}}>{t("unitOfIns")} {lang === 'en' ? t('perCarbPortion') : t('perServing')}</span>
                        <span style={{width: '150px'}}>{lang === 'en' ? t('rangeOfCarbPortion') : t("perServing")}</span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>
                            <span style={{width: '300px'}}
                                  className={'fw-bold'}> {lang === 'en' ? t("carbOptionDefinition") : t("servingsDefinition")}</span>
                        <span style={{width: '150px'}}>{t("gramsOf")} </span>
                        <span style={{width: '150px'}}>{t("rangeServDef")}</span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>    <span style={{width: '300px'}}
                                                                              className={'fw-bold'}> {t("mealTimeInsulin")}</span>
                           <span style={{width: '150px'}}>{t("unitOfIns")} </span>
                           <span style={{width: '150px'}}>{t("mealTimeRange")}</span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>
                            <span style={{width: '300px'}}
                                  className={'fw-bold'}> {t("correctionFactor")}</span>
                           <span style={{width: '150px'}}>1 {t("unitFor_long")} X {t('dynamicValues.unit')}</span>
                           <span style={{width: '150px'}}>{t("corrFactRange")}</span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>
                            <span style={{width: '300px'}}
                                  className={'fw-bold'}> {t("insulinDuration")} {t("insDurationText")} </span>
                           <span style={{width: '150px'}}>{t("hours")} </span>
                           <span style={{width: '150px'}}>{t("easy")}: {t('insulinDurationRange_Easy')}
                            <br/> {t("advanced")}: {t('insulinDurationRange_Advanced')}</span>
                    </p>

                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>
                            <span style={{width: '300px'}}
                                  className={'fw-bold'}> {t("doseIncrement")} </span>
                           <span style={{width: '150px'}}>{t("unitOfIns")} </span>
                           <span style={{width: '150px'}}>{t("doseIncrementRange")}</span>
                    </p>
                    <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                    <p className={'d-flex justify-content-between'}>
                            <span style={{width: '300px'}}
                                  className={'fw-bold'}> {t("maximumInsDose")} </span>
                        <span style={{width: '150px'}}>{t("unitOfIns")} </span>
                        <span style={{width: '150px'}}>{t("maximumInsDoseRange")}</span>
                    </p>


                </Col>


            </Row>


        </>
    )
}

export default ReaderSpecifications