import {useState} from "react";
import {useTranslation} from "../../Utils/UseTranslation";
import {Col} from "react-bootstrap";
import parse from "html-react-parser";
import {CiCircleInfo} from "react-icons/ci";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import ModalNote from "../../Components/Modal/ModalNote";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";

const ChangeReaderSettings = () => {
    const [turnOnOff, setTurnOnOff] = useState(true)
    const [homeBtnBehavior, setHomeBtnBehavior] =useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const [fromSettingAlarms, setFromSettingAlarms]=useState(true)

    const {t} = useTranslation()
    const changingSettingsList_1 = t('changingSettingsList_1', {
        settingsImageToken: '%SETTINGS_IMG%',
        settingsImageValue: '<img src="/icons/settings.png" />'
    });;
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("changingReaderSettings")}</h2>
                {/*<p>{t('infoList4_text_2', { measureUnit: i18next.t('dynamicValues.unita_misura')})}</p>*/}

                <p className={''}>{t("changingSettingsText")}</p>

                <p className={'text-orange fw-bold mb-0 mt-2'}> {t("howTo")}</p>

                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{changingSettingsList_1}</span>

                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 '}>
                    <span>{t("changingSettingsList_2")} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>

                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("changingSettingsList_3")}</span>


                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-2 d-flex align-items-center justify-content-between'}>
                    <span>{t("changingSettingsList_4")}</span>


                </p>

            </Col>
            <Col md={6} className={'align-self-center'}>
                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior} setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff && <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep} setCurrentStep={setCurrentStep} fromSettingAlarms={fromSettingAlarms}  />}

                </ReaderEmpty>
                <p className={'fw-bold text-start mt-4 text-smaller'}>{t('disclaimer')}</p>

            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/setting-reminders'} nextUrl={'/professional-options'}/>
            </Col>
        </>

    )
}

export default  ChangeReaderSettings
