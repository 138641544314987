import {Button, Col, Form, Row} from "react-bootstrap";
import {CiSquarePlus} from "react-icons/ci";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import React, {useEffect, useState} from "react";

import {FiArrowDownRight, FiArrowRight, FiArrowUpRight} from "react-icons/fi";
import {useTranslation} from "../../../../../Utils/UseTranslation";

const Logbook = (props) => {
    const {t} = useTranslation()
// Dichiarazione di uno stato per tenere traccia dell'indice del gruppo corrente
    const [currentGroup, setCurrentGroup] = useState(0);

// Funzione per mostrare il gruppo successivo
    const showNextGroup = () => {
        setCurrentGroup(currentGroup + 1);
    };
    console.log(props.firstActivation)
// Funzione per mostrare il gruppo precedente
    const showPreviousGroup = () => {
        setCurrentGroup(currentGroup - 1);
    };
    // Aggiungo il valore prima del rendering se provengo da Cheching Glucose
    if (props.isFromCheckingAnimation) {
        const newValue = {
            glucoseValue: 'inRange',
            numericValue: t('dynamicValues.inRange'),
            rapidActing: 2,
            longActing: 3,
            icon: 'blood.png',
            date: new Date()
        };
        props.glucoseReading.unshift(newValue);
        console.log(props.glucoseReading);
    }

// Calcolo del numero totale di gruppi
    const totalGroups = Math.ceil(props.glucoseReading.length / 4);

// Filtraggio delle voci da mostrare basate sul gruppo corrente
    const itemsToShow = props.glucoseReading
        .slice(currentGroup * 3, (currentGroup + 1) * 3)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .map((value, index) => {
            const time = value.date && new Date(value.date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            });
            console.log(time, value.date)
            return (
                <Row key={index}
                     className={' py-2 bkg-ligth-grey-2 my-1 w-100 mx-0 d-flex border-groove text-start align-items-center'}>
                    <Col><p className={'small mb-0'} style={{lineHeight: '1em'}}> {time} </p></Col>
                    <Col className={'px-0'} key={index}>
                        <h3 className={'px-0 mb-0'}>   {value.numericValue} {value.icon && (<img src={'/icons/'+value.icon} /> )}
                            {value.glucoseValue === 'inRange' && (<FiArrowRight className={'fs-2 fw-bold'}/>)}
                            {value.glucoseValue === 'goingLow' && (<FiArrowDownRight className={'fs-2 fw-bold'}/>)}
                            {value.glucoseValue === 'Low' && (<FiArrowDownRight className={'fs-2 fw-bold'}/>)}
                            {value.glucoseValue === 'High' && (<FiArrowUpRight className={'fs-2 fw-bold'}/>)}
                            {value.glucoseValue === 'GoingHigh' && (<FiArrowUpRight className={'fs-2 fw-bold'}/>)}
                        </h3>
                    </Col>
                </Row>
            );
        });

// Aggiunta delle freccette per navigare tra i gruppi
    const navigationArrowsPrev = (
        <div>
            {currentGroup > 0 && (
                <Row className={'justify-content-center text-center mb-0 mx-0'}>
                    <div className={'text-center'}>
                           <span onClick={showPreviousGroup}>
                                 <BiSolidUpArrow style={{
                                     fontSize: '30px',
                                     color: 'var(--light-grey-2)',
                                     filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                 }}/>
                            </span>
                    </div>
                </Row>

            )}

        </div>
    );

    const navigationArrowsNext = (
        <div>
            {currentGroup < totalGroups - 1 && (
                <Row className={'justify-content-center text-center mb-0 mx-0'}>
                    <div className={'text-center'}>
                    <span onClick={showNextGroup}>
                    <BiSolidDownArrow style={{
                        fontSize: '30px',
                        color: 'var(--light-grey-2)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                        </span>

                    </div>

                </Row>
            )}
        </div>
    )

    return (

        (props.firstActivation && !props.sensorIsActive) ? (
            <Row
                className={`mx-0 h-100 text-start overflow-hidden align-content-start`}>
                <Col md={8} xs={8} className={'align-items-center'}>
                    <b> {t('logbook')}</b>
                </Col>
                <Col md={4} xs={4} className={'text-end px-0 position-relative'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                        props.navigateToComponent('reviewHistory')
                    }}>OK</Button>
                </Col>

                <Col md={12}
                     className={`align-content-start d-inline-flex flex-column  mt-5`}>
                    <p>{t('logbookNoData')}</p>


                </Col>
            </Row>
        ) : (
            <Row
                className={`mx-0 h-100 text-start overflow-hidden `}>
                <Col md={8} className={'align-items-center'}>
                    <b> {t('logbook')}</b>
                </Col>
                <Col md={4} className={'text-end px-0 position-relative'}>
                    <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                            style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                        props.navigateToComponent('reviewHistory')
                    }}>OK</Button>
                </Col>
                <Col md={12}>
                    {navigationArrowsPrev}
                    {itemsToShow}
                    {navigationArrowsNext}
                </Col>
            </Row>
        )

    )
}

export default Logbook