import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/fa";

const SensorNav = (props) => {
    return (
        <Row className={'bg-white shadow mx-0 text-black p-2 text-start subMenu px-0'}>
            <Col md={12}>
             {/*   <p className={'fw-bold text-blue mb-0 pb-2'}>
                    {props.t('sensor')}
                </p>*/}

                <ul className={'list-unstyled text-start px-0'}>
                    <li className={'p-2 '}>
                        <Link to={'/application-site-selection'}  onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('applicationSiteSelect')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link  to={'/preparing-application-site'}  onClick={props.handleClickInner}  className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('applicationSitePrepare')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link  to={'/preparing-sensor-applicator'}  onClick={props.handleClickInner}  className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('preparingSensorApplicator')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/applying-sensor'}  onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('applicationSensor')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/removing-sensor'}  onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('removingSensor')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                    <li className={'p-2 '}>
                        <Link to={'/starting-sensor'}  onClick={props.handleClickInner} className={'d-inline-flex w-100 justify-content-between '}>
                            {props.t('sensorStart')}
                         {/*   <span className={'text-end text-white'}>
                                <FaChevronRight/>
                            </span>*/}
                        </Link>
                    </li>
                </ul>
            </Col>

        </Row>
    )
}
export default SensorNav