import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import applicationSite from "../../assets/img/Sensor/application-site-selection-sm.png"
import './../../assets/css/Sensor/Sensor.css'
import video from './../../assets/videos/start-sensor.mp4'
import {Link} from "react-router-dom";
import Video from "../../Components/Layout/Elements/VideoPlayer";
const CheckingWSensor = () => {
    const {t, i18n} = useTranslation()

    const lang = localStorage.getItem('lang');
    const videoRef = useRef(null);


    useEffect(() => {
        console.log(video)
    }, [video])

    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("checkingWSensor")}</h2>
                <p className={'text-orange fw-bold mb-0 mt-4'}> {t("howTo")}</p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("checkingWSensor_List_1"))}  </span>
                </p>
                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("checkingWSensor_List_2"))}  </span>

                </p>

                <hr className={'text-orange my-1 opacity-100 mb-3'}/>
                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("checkingWSensor_List_3"))}  </span>

                </p>
            </Col>
            <Col md={6} className={'align-self-start position-relative'}>
                <Video video={video} t={t} videoRef={videoRef}></Video>
            </Col>
            <Col md={12} className={'text-start align-self-end'}>

                <NavigationButtons t={t} prevUrl={'/starting-sensor'} nextUrl={'/understanding-readings'}/>
            </Col>
        </>


    )
}

export default CheckingWSensor