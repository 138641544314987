import React, {useContext, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import '../../assets/css/Icon.css'
import Header from "./Header";
import LanguageSelection from "../../Pages/LanguageSelection/LanguageSelection";
import Login from "../../Pages/Login/Login";
import AuthContext from "../../Context/AuthContext";
import Floating from "./Elements/Floating";
import logo from '../../assets/img/logo-FSL2.png'
import abbotLogo from '../../assets/img/Home/abbott-logo-2.png'

const Layout = () => {
    const navigate = useNavigate();
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : '')
    const {isAuthenticated} = useContext(AuthContext)
    const location = useLocation();
    const [isReaderPage, setIsReaderPage] = useState(false)
    const [alarmSound, setAlarmSound] = useState()
    useEffect(() => {
        console.log(location)
        if (location.pathname === '/first-time-reader-setup' || location.pathname === '/setting-reminders' || location.pathname === '/setting-alarms') {
            setIsReaderPage(true)
        } else {
            setIsReaderPage(false)
        }

    }, [location])
    useEffect(() => {
        console.log('isreaderPage', isReaderPage)
    }, [isReaderPage])
    useEffect(() => {
        setSelectedLanguage(localStorage.getItem('lang'))
        console.log(selectedLanguage)
    }, [selectedLanguage, localStorage.getItem('lang')])


    return (

        <>
            {!isAuthenticated ? <Login/> : (
                !selectedLanguage ? <LanguageSelection/> : (
                    <Container className={'p-0 h-100'}>
                        <Row className={'bkg-grey mx-0 justify-content-between'}>
                            <Col md={2}><img src={logo} className={'img-fluid'} style={{width: '200px'}}/></Col>
                            <Col md={2}><img src={abbotLogo} className={'img-fluid'} style={{width: '250px'}}/></Col>
                        </Row>
                        <Header/>
                        <Row className="container-fluid mainContent px-0 mx-0 border px-0">

                            <Row
                                className={` me-auto mw-100 mx-0 px-0 w-100 justify-content-stretch innerMainContent ${location.pathname == '/home' ? '' : 'pt-3'} `}>
                                <Outlet context={[alarmSound, setAlarmSound]}/>
                            </Row>
                        </Row>
                        <Row className={'text-start mt-2'}>
                            <Col>{/*<img src={logo} className={'img-fluid'} style={{width: '200px'}}/>*/}</Col>
                        </Row>
                        <Floating setSelectedLanguage={setSelectedLanguage} isReaderPage={isReaderPage}
                                  setAlarmSound={setAlarmSound} alarmSound={alarmSound}/>
                    </Container>
                )
            )}
        </>
    )
}
export default Layout