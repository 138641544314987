import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {Link} from "react-router-dom";

const AlarmsSettings = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [triggerRender, setTriggerRender] = useState(false); // Stato di trigger per forzare il re-rendering
    const [count, setCount] = useState(0)

    useEffect(() => {
        console.log(props.alarmsSettings)
        console.log(props.alarmActivated)
    }, [props.alarmsSettings])


    useEffect(() => {

        if (!sessionStorage.getItem('activatedAlarm') &&

            (props.alarmActivated && props.alarmActivated.lowAlarm == true || props.alarmActivated &&  props.alarmActivated.highAlarm == true)) {
            sessionStorage.setItem('activatedAlarm', 'true');
            props.setAlarmActivated(prevValue => {

                return {...prevValue, ['signalLossAlarm']: true};

            });
            props.setAlarmType('signalLoss');
            props.navigateToComponent('alarmsInfos');
        }
    }, [props.alarmActivated]);
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden justify-content-start`}>
            {!props.alarmsSettings ? (

                <>
                    <Col md={8} className={'align-items-center'}>
                        <b>{t('alarmsSettings')}  </b>
                    </Col>
                    <Col md={4} className={'text-end px-0 position-relative'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2  upperBtn ps-2'}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                            props.navigateToComponent('settings')
                        }}>OK</Button>
                    </Col>

                    <Col md={12}>
                        <p className={'lowGlucose d-flex justify-content-between'}><span>{t('Low')} </span>
                            <span>{props.alarmsValues && props.alarmsValues.lowAlarm && props.alarmActivated.lowAlarm !== false ? props.alarmsValues.lowAlarm  + " " + t("dynamicValues.unit") : t("off")}</span>
                        </p>
                        <p className={'highGlucose  d-flex justify-content-between'}><span>{t('High')} </span>
                            <span>
  {props.alarmsValues && props.alarmsValues.highAlarm && props.alarmActivated.highAlarm !== false
      ? props.alarmsValues.highAlarm + " " + t("dynamicValues.unit")
      : t("off")}
</span>
                        </p>
                        <p className={'signalLoss d-flex justify-content-between'}><span>{t('signalLoss')}</span>
                            <span>{props.alarmActivated && props.alarmActivated.signalLossAlarm === true ? t("on") : t("off")}</span>
                        </p>

                    </Col>
                    <Col className={'d-flex align-items-end mb-2'}><Button
                        className={'btn-secondary small text-black w-100'} onClick={() => {
                        props.setIsAlarmSettings(true)
                    }}> {t("changeAlarmsSettings")} </Button>
                    </Col>
                </>

            ) : (
                <>

                    <Col md={8} className={'align-items-center'}>
                        <b>{t('changeAlarmsSettings')}  </b>
                    </Col>
                    <Col md={4} className={'text-end px-0 position-relative'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                            props.setIsAlarmSettings(false)
                        }}>OK</Button>
                    </Col>
                    <Col className={'d-flex justify-content-around flex-column'}>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.setAlarmType('lowAlarm')
                                          props.navigateToComponent('alarmsChange')

                                      }}>
                                   <span
                                       className={'small'}>{t("lowAlarm")}</span></Link>

                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.setAlarmType('highAlarm')
                                          props.navigateToComponent('alarmsChange')
                                      }}>
                                    <span
                                        className={'small'}>{t("highAlarm")}</span></Link>
                            </Col>

                        </Row>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={12} className={'px-1'}>
                                <Link className={'btn btn-secondary w-100 text-black border-groove text-start'}
                                      onClick={() => {
                                          props.setAlarmType('signalLossAlarm')
                                          props.navigateToComponent('alarmsChange')
                                      }}>
                                   <span
                                       className={'small'}> {t("signalLossAlarm")}</span></Link>
                            </Col>

                        </Row>
                    </Col>
                </>
            )}
            <Col md={12}></Col>
        </Row>

    )
        ;
}

export default AlarmsSettings