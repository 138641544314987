import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {CiSquarePlus} from "react-icons/ci";

const AlarmInfos = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [triggerRender, setTriggerRender] = useState(false); // Stato di trigger per forzare il re-rendering


    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden`}>
            <Col md={8} className={'align-items-center'}>
                <b>  <strong>{t(props.alarmType)}</strong> </b>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                    if (props.prev === 'alarmsSettings') {
                        console.log('test')
                        // Usa sessionStorage invece di localStorage
                        props.navigateToComponent('alarmsSettings');
                    } else {
                        props.navigateToComponent('alarmsChange');
                    }

                        } }>OK</Button>
            </Col>

            <Col md={12}>
                <p>{t(props.alarmType+'_infos')}</p>
            </Col>

        </Row>
    )
        ;
}

export default AlarmInfos