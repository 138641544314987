import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import CustomSwitcher from "../../../../../Layout/Elements/CustomSwitcher";
import {Link} from "react-router-dom";


const AlarmsChange = (props) => {
    const {t, i18n} = useTranslation();
    const [unit, setUnit] = useState(props.alarmType === 'lowAlarm' ? parseInt(t('dynamicValues.alarmsSettings.low')) : parseInt(t('dynamicValues.alarmsSettings.high')))
    const [active, setActive] = useState(false)
    console.log(props.alarmActivated)
    useEffect(() => {
        if (props.alarmsValues && props.alarmsValues[props.alarmType])
        setUnit(props.alarmsValues[props.alarmType])
    }, [props.alarmsValues])
    const handleUnitChange = (e) => {
        console.log(unit)
        const action = e.currentTarget.getAttribute('data-action')
        if (action === 'grow') {
            setUnit(prevState => props.alarmType === 'lowAlarm' ? prevState +  5  : prevState +  10)
        } else if (action !== 'grow' && unit > 0) {
            setUnit(prevState => props.alarmType === 'lowAlarm' ? prevState -  5  : prevState -  10)
        }
    }

    useEffect(() => {
        console.log(props.alarmActivated)
    }, [props.alarmActivated])


    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden align-content-between`}>
            <Col md={8} className={'align-items-center'}>
                <b>  {t(props.alarmType)}</b>
            </Col>

            {props.alarmType !== 'signalLossAlarm' && (
                <>
                    <Col md={4} className={'text-end px-0 position-relative'}>
                        <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                                style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}}
                                onClick={() => {props.navigateToComponent('alarmsInfos')}}>?</Button>
                    </Col>
                    <Col md={12}
                         className={`align-items-center d-inline-flex justify-content-between flex-row col-md-12`}>
                        <div className={'mt-2 justify-content-around'}>
                            <div className={'position-relative'}>
                    <span onClick={handleUnitChange} data-action={'grow'}>
                        <BiSolidUpArrow className={`arrowIcon ${!active && 'disabled'}`} style={{
                            fontSize: '50px',
                            color: 'var(--light-grey)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))',
                            cursor: 'pointer'
                        }}/>
                    <span className={'text-black fs-4 position-absolute'}
                          style={{left: '18px', top: '10px', cursor: 'pointer', userSelect: 'none'}}> + </span>

                    </span>

                            </div>
                            <div className={'position-relative'}>
                    <span onClick={handleUnitChange}>

                        <BiSolidDownArrow className={`arrowIcon ${!active && 'disabled'}`} style={{
                            fontSize: '50px',
                            color: 'var(--light-grey)',
                            filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                        }}/>
                    <span className={'text-black fs-3 position-absolute'}
                          style={{left: '20px', top: '2px', cursor: 'pointer', userSelect: 'none'}}> - </span>

                    </span>

                            </div>
                        </div>

                        <div className={'mt-2 text-center justify-content-around'}>

                            <h2> {!active ? '--' :  unit} <br/>
                                <span className={'fs-5'}>{!active ? '' : t("dynamicValues.unit")}</span>
                            </h2>
                        </div>


                    </Col>
                </>

            ) }
            <CustomSwitcher active={active} setActive={setActive} alarmActivated={props.alarmActivated} setAlarmActivated={props.setAlarmActivated} alarmType={props.alarmType}/>
            <Col>{props.alarmType === 'signalLossAlarm' && (<p className={'text-smaller'}>{t("signalLossAlarm_text")}</p>)}</Col>
            <Col md={6} className={'offset-6 text-end mt-2 mb-1 px-1'}>
                <Link onClick={() => {
                    props.setIsAlarmSettings(true);

                    props.setAlarmsValues(prevValue => {
                        // Controlla se il tipo di allarme è signalLossAlarm
                        if (props.alarmType === 'signalLossAlarm') {
                            // Se il tipo di allarme è signalLossAlarm, usa il valore del checkbox
                            return { ...prevValue, signalLossAlarm: active };
                        } else {
                            // Altrimenti, esegui la logica precedente basata su unit
                            // Controlla se un allarme con lo stesso tipo è già presente nell'oggetto
                            if (prevValue[props.alarmType] === undefined) {
                                // Se l'allarme non esiste già, crea un nuovo oggetto con la nuova proprietà
                                return { ...prevValue, [props.alarmType]: unit };
                            } else {
                                // Se l'allarme con lo stesso tipo è già presente, aggiorna solo il suo valore
                                return { ...prevValue, [props.alarmType]: unit };
                            }
                        }
                    });
                    props.navigateToComponent('alarmsSettings')
                }} className={'btn btn-primary w-100'} to={''}>{t('done')}</Link>
            </Col>

        </Row>
    )

}

export default AlarmsChange