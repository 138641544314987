import {useEffect, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import HomeScreenHeader from "./HomeScreenHeader";
import sound from './../../../../assets/sounds/succesful-scan.mp3'
import {useTranslation} from "react-i18next";
import {FiArrowDownRight, FiArrowRight, FiArrowUpRight} from "react-icons/fi";
import waiting_1 from "../../../../assets/img/Reader/Settings/waiting1.jpg";
import waiting_2 from "../../../../assets/img/Reader/Settings/waiting2.jpg";
import waiting_3 from "../../../../assets/img/Reader/Settings/waiting3.jpg";


const GlucoseReadings = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [isDetail, setIsDetail] = useState(true)
    const [image, setImage] = useState(null);
    const [imageInactive, setImageInactive] = useState(`/translations/${lang}/noActive.jpg`)
    const [isNote, setIsNote] = useState(false)
    const [audio, setAudio] = useState(null)

    const [foodIcon, setFoodIcon] = useState(false)
    const [insulinIcon, setInsulinIcon] = useState(false)
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const [loopImage, setLoopImage] = useState(false)
    let imageArray = [waiting_1, waiting_2, waiting_3, waiting_1, waiting_2, waiting_3];
    const intervalIdRef = useRef(null);
    const [repeatCount, setRepeatCount] = useState(0);

    useEffect(() => {
        if (props.isBloodGlucose) {
            const intervalId = setInterval(() => {
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % imageArray.length);
            }, 1000); // Cambia immagine ogni 3 secondi

            // Salva intervalId nella variabile ref
            intervalIdRef.current = intervalId;

            return () => clearInterval(intervalId);


        }
    }, [props.isBloodGlucose]);

    useEffect(() => {
        console.log(props.prevStep === 'notes')
        if (props.prevStep === 'notes') {
            setLoopImage(true)
        }
    }, [props.prevStep])
    useEffect(() => {
        console.log(loopImage)

    }, [loopImage])
    useEffect(() => {

        // Controlla se abbiamo visualizzato l'ultima immagine e ferma l'intervallo
        if (props.isBloodGlucose) {
            if (currentImageIndex >= imageArray.length - 1) {
                setTimeout(() => {
                    if (repeatCount < 1) {
                        setCurrentImageIndex(3); // Ripeti le ultime tre immagini
                        setRepeatCount(prevCount => prevCount + 1);
                    } else {
                        clearInterval(intervalIdRef.current);
                        setLoopImage(true);
                    }
                }, 500);
            }

        }
    }, [currentImageIndex, repeatCount, props.isBloodGlucose]);
    useEffect(() => {
        // setImage(require(`../../../../assets/img/translations/${lang}/${behavior}.jpg`))
        setImage(`/translations/${lang}/${props.behavior[props.behaviorIndex]}.jpg`)
        !props.isStatic && props.behavior[props.behaviorIndex] !== 'noActive' && localStorage.setItem('glucoseValue', props.behavior[props.behaviorIndex])
        !props.isStatic && props.behavior[props.behaviorIndex] !== 'noActive' && localStorage.setItem('glucoseNumericValue', t(`dynamicValues.${props.behavior[props.behaviorIndex]}`))
        !props.isStatic && props.behavior[props.behaviorIndex] !== 'noActive' && localStorage.setItem('date', new Date().toISOString())
    }, [lang, props.behavior])

    console.log(props.isBloodGlucose)
    useEffect(() => {
        // Recupera l'array memorizzato in localStorage
        const arrayMemorizzato = JSON.parse(localStorage.getItem('checked'));
        console.log(arrayMemorizzato)
        if (Array.isArray(arrayMemorizzato)) {

            // Esegui altre operazioni con le voci dell'array qui, se necessario
            arrayMemorizzato.forEach(voce => {
                if (voce === 'rapidActing' || voce === 'longActing') {
                    setInsulinIcon(true)
                } else if (voce === 'food') {
                    setFoodIcon(true)
                }
                console.log(voce);
            });
        }
    }, [localStorage.getItem('checked')])
    console.log(props.behavior)
    useEffect(() => {
        let newAudio;
        if (!props.isStatic && !props.isFromAddingNotes) {
            newAudio = new Audio(sound);
            newAudio.play();
            newAudio.loop = false;
        }
        return () => {
            if (newAudio) {
                newAudio.pause();
            }
        };
    }, [props.isStatic, props.isFromAddingNotes]);
    return (

        <Row className={'mx-0 h-100 text-start justify-content-start overflow-hidden'}>

            <Col md={12} className={'align-items-center'}>
                <HomeScreenHeader firstActivation={props.firstActivation} isDetail={isDetail}
                                  behavior={props.behavior}
                                  behaviorIndex={props.behaviorIndex} navigateToComponent={props.navigateToComponent}
                                  sensorIsActive={props.sensorIsActive}
                                  t={t} isStatic={props.isStatic} isFromAddingNotes={props.isFromAddingNotes}
                                  isBloodGlucose={props.isBloodGlucose}/>


            </Col>

            <Col md={12} className={'align-items-center px-0 text-end'}>
                {(props.sensorIsActive && image && !props.isBloodGlucose) &&

                    (<>
                            <Row className={`flex-row d-flex align-items-center ${props.isStatic && 'mx-0'}`}
                                 style={{background: '#fff'}}>
                                <Col>
                                    <h2 className={'d-inline-block mb-0'} style={{
                                        fontSize: (props.isStatic && props.behavior[props.behaviorIndex] === 'reader_110') ? '40px' : '65px',
                                        fontWeight: 800
                                    }}>

                                        {!props.isStatic ?
                                            (
                                                <span>{t(`dynamicValues.${props.behavior[props.behaviorIndex]}`)}</span>
                                            ) :
                                            <>
                                           <span> {props.behavior[props.behaviorIndex] === 'reader_110' &&

                                               <img
                                                   src={'/icons/icona-sangue.png'}
                                                   className={'bkg-ligth-grey border-groove rounded-2 me-2'}/>
                                           }
                                               {t(`dynamicValues.treatmentDecisionsValue.${props.behavior[props.behaviorIndex]}`)}
                                               </span>
                                            </>
                                        } < /h2>
                                    <div className={'d-inline-block'}> <span>
                                {(props.behavior[props.behaviorIndex] === 'inRange' || props.behavior[props.behaviorIndex] === 'reader_110' || props.behavior[props.behaviorIndex] === 'reader_72' || props.behavior[props.behaviorIndex] === 'reader_204') && (
                                    <FiArrowRight className={'fs-1 fw-bold'}/>)}
                                        {props.behavior[props.behaviorIndex] === 'goingLow' && (
                                            <FiArrowDownRight className={'fs-1 fw-bold'}/>)}
                                        {props.behavior[props.behaviorIndex] === 'Low' && (
                                            <FiArrowDownRight className={'fs-1 fw-bold'}/>)}
                                        {(props.behavior[props.behaviorIndex] === 'High' || props.behavior[props.behaviorIndex] === 'reader_115') && (
                                            <FiArrowUpRight className={'fs-1 fw-bold'}/>)}
                                        {props.behavior[props.behaviorIndex] === 'GoingHigh' && (
                                            <FiArrowUpRight className={'fs-1 fw-bold'}/>)}

                            </span> <br/>
                                        <span>{t(`dynamicValues.unit`)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className={'position-relative mx-0 mb-5'}>
                                <div style={{top: '10px', right: '25px'}} className={'position-absolute img-fluid'}>
                                    {foodIcon && <img src={'/icons/food.png'} className={'img-fluid'}/>}
                                    {insulinIcon && <img src={'/icons/insulin.png'} className={'img-fluid'}/>}

                                </div>
                                <img src={image} className={'img-fluid'}/>
                            </Row>


                        </>
                    )

                }

                {!props.sensorIsActive && <img src={imageInactive} className={'img-fluid'}/>}

                {
                    props.isBloodGlucose &&

                    !loopImage ?
                        (

                            <Row className={'align-self-start'}>
                                <Col className={'px-0'}>
                                    <img src={imageArray[currentImageIndex]} alt="Immagine" className={'img-fluid'}/>
                                </Col>
                            </Row>
                        ) :
                        (
                            <>
                                <Row className={'align-self-start mb-5'}>
                                    <Col className={'d-flex flex-row justify-content-end align-items-center'}>
                                        <h2 className={'d-inline-block mb-0'} style={{
                                            fontSize: (props.isStatic && props.behavior[props.behaviorIndex] === 'reader_110') ? '40px' : '65px',
                                            fontWeight: 800
                                        }}><span>{t(`dynamicValues.inRange`)}</span>
                                            {/*<FiArrowRight className={'fs-1 fw-bold'}/>*/}


                                        < /h2>
                                        <div className={'text-center'}>
                                            <img src={'/icons/blood.png'}/><p className={'mb-0 px-1'}
                                                                              style={{borderBottom: '1px solid #333'}}>{t("dynamicValues.splittedUnit.first")}</p>
                                            <p className={'mb-0 px-1'}>{t("dynamicValues.splittedUnit.second")}</p>
                                        </div>
                                    </Col>
                                    {props.prevStep === 'notes' && (
                                        <Row className={'position-relative mx-0'}>
                                            <div style={{top: '10px', right: '25px'}}
                                                 className={'position-absolute img-fluid'}>
                                                {foodIcon && <img src={'/icons/food.png'} className={'img-fluid'}/>}
                                                {insulinIcon &&
                                                    <img src={'/icons/insulin.png'} className={'img-fluid'}/>}

                                            </div>
                                            <img src={image} className={'img-fluid'}/>
                                        </Row>
                                    )}

                                </Row>

                            </>

                        )
                }

            </Col>

        </Row>
    );
}

export default GlucoseReadings