import {Col, Row} from "react-bootstrap";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import reader from "../../assets/img/Reader/reader_empty.png";
// import {useTranslation} from "react-i18next";
import {CiCircleInfo} from "react-icons/ci";
import {HiExclamationTriangle} from "react-icons/hi2";
import ModalNote from "../../Components/Modal/ModalNote";
import {useEffect, useState} from "react";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import SensorKit from "../ProductOverview/SensorKit";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";
import parse from "html-react-parser";
import {useTranslation} from "../../Utils/UseTranslation";
const FirstTimeSetup = () => {
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [turnOnOff, setTurnOnOff] = useState(false)
    const [homeBtnBehavior, setHomeBtnBehavior] = useState(false)
    const [currentStep, setCurrentStep] = useState();
    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        if (turnOnOff) {
            setCurrentStep(0)
        }

    }, [turnOnOff])
    const {t} = useTranslation('translation')
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("firstTimeReader")}</h2>

                <p className={'mb-0'}>{t("firstTimeSetup_text")}</p>

                <p className={'text-orange fw-bold mb-0 mt-2 d-flex align-items-center justify-content-between'}> {t("howTo")} <span><HiExclamationTriangle onClick={() => {
                    setShow(true)
                    setTitle(t('caution'))
                    setText(t('firstTimeSetup_warning'))
                }} className={'fs-3 text-end'}/></span></p>

                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'mb-0'}>{t("firstTimeSetup_list1")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-inline-flex align-items-center'}>
                    <span>{t("firstTimeSetup_list2")} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0'}>{t("firstTimeSetup_list3")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("firstTimeSetup_list4")}</span>

                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0'}>{t("firstTimeSetup_list5")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0'}>{t("firstTimeSetup_list6")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>

                <p className={'fw-bold text-start mt-4 text-smaller mt-5 mx-5'}>{t("disclaimer2")}</p>
            </Col>
            <Col md={6} className={'align-self-center'}>
                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior}
                             setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff && <ScreenContainerFirstTime setTurnOnOff={setTurnOnOff} turnOnOff={turnOnOff}
                                                            setHomeBtnBehavior={setHomeBtnBehavior}
                                                            currentStep={currentStep} setCurrentStep={setCurrentStep} />}

                </ReaderEmpty>
                <p className={'fw-bold text-start mt-4 text-smaller'}>{t('disclaimer')}</p>

                {/*  <div className={'readerContainer'}>
                    <img src={reader} alt="ReaderHomeScreen" style={{width: "100%", height: "auto"}}/>
                    <div className={'innerReaderContainer'}>

                    </div>
                </div>*/}


            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/sensor-glucose-reading'} nextUrl={'/reader-homescreen'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>

    )
}
export default FirstTimeSetup