
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {Col} from "react-bootstrap";
import activeSensor from './../../assets/img/Icons/activeSensor.png'
import direction from './../../assets/img/Icons/directionGlucoseReader.png'
import caution from './../../assets/img/Icons/caution.png'
import prevNext from './../../assets/img/Icons/viewPrevNext.png'
import soundsOn from './../../assets/img/Icons/soundsVibrationON.png'
import soundsOffVibON from './../../assets/img/Icons/SoundOffVibrationON.png'
import soundsOnVibOff from './../../assets/img/Icons/SoundONVibrationOff.png'
import soundsOff from './../../assets/img/Icons/SoundAndVibrOFF.png'
import sensorCommunicating from './../../assets/img/Icons/SensorCommunicating.png'
import sensorNotCommunicating from './../../assets/img/Icons/SensorNotCommunicating.png'
import bloodIcon from './../../assets/img/Icons/bloodIcon.png'
import notes from './../../assets/img/Icons/notes.png'
import addNotes from './../../assets/img/Icons/addNotes.png'
import timeChanged from './../../assets/img/Icons/timeChanged.png'
import bloodTest from './../../assets/img/Icons/bloodTest.png'
import controlSolution from './../../assets/img/Icons/controlSolutionResult.png'
import lowBattery from './../../assets/img/Icons/lowBattery.png'
import batteryCharging from './../../assets/img/Icons/batteryCharging.png'
import sensorHot from './../../assets/img/Icons/sensorHot.png'
import sensorCold from './../../assets/img/Icons/sensorCold.png'
import foodNote from './../../assets/img/Icons/foodNote.png'
import insulinNote from './../../assets/img/Icons/insulinNote.png'
import estimatedInsulin from './../../assets/img/Icons/estimatedActiveIns.png'
import settings from './../../assets/img/Icons/settingsSymbol.png'
import infos from './../../assets/img/Icons/infos.png'
import calculator from './../../assets/img/Icons/calculator.png'
import {useTranslation} from "../../Utils/UseTranslation";
const ReaderSymbols = () => {
    const {t} = useTranslation()

    return (
        <>
            <Col md={12} className={'text-start'}><h2> {t("readerSymbols")}</h2></Col>
            <Col md={4} className={'text-start justify-content-start ps-4 align-self-start align-content-start fixedHeightP'}>

                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={activeSensor}/></span> {t("activeSensor")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={direction}/></span> {t("directionGlucose")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={caution}/></span> {t("caution")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={prevNext}/></span> {t("viewPrevNext")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>

                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={notes}/></span> {t("notes")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={addNotes}/></span> {t("addInfoNotes")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={foodNote}/></span> {t("foodNote")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={insulinNote}/></span> {t("rapidActInsuline")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={timeChanged}/></span> {t("timeChanged")}</p>




            </Col>
            <Col md={4} className={'text-start justify-content-start ps-4 align-self-start'}>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={soundsOn}/></span> <span>{t("soundsVibOn")}</span></p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={soundsOnVibOff}/></span> <span>{t("soundsOnVibOff")}</span></p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={soundsOffVibON}/></span> <span>{t("soundsOffVibOn")}</span></p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={soundsOff}/></span> <span>{t("soundsVibOff")}</span></p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={sensorCommunicating}/></span> {t("sensorCommunicating")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={sensorNotCommunicating}/></span> {t("sensorNotCommunicating")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={bloodTest}/></span> {t("bloodGlucoseTest")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={controlSolution}/></span> {t("controlSolutionResult")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>




            </Col>
            <Col md={4} className={'text-start justify-content-start ps-4 align-self-start'}>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={calculator} /></span> {t("calculator")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={infos} /></span> {t("infos")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={lowBattery}/></span> {t("lowBattery")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={batteryCharging}/></span> {t("batteryCharging")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={sensorCold}/></span> {t("sensorCold")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={sensorHot}/></span> {t("sensorHot")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={estimatedInsulin} /></span> {t("estimatedInsulin")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>
                <p className={'d-flex align-items-center py-1 my-1'}><span><img src={settings}/></span> {t("settings")}</p>
                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>


            </Col>
                <Col md={12} className={'text-start align-self-end'}>  <NavigationButtons t={t} prevUrl={'/important-informations'} nextUrl={'/system-specifications'}/>
                </Col>
        </>


    )
}

export default ReaderSymbols