import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import HomeScreenHeader from "./HomeScreenHeader";

import {useTranslation} from "react-i18next";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {use} from "i18next";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {CiSquarePlus} from "react-icons/ci";

const Notes = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [triggerRender, setTriggerRender] = useState(false); // Stato di trigger per forzare il re-rendering
    const [isDetail, setIsDetail] = useState(true)
    const [steps, setStep] = useState(1)

    const [checked, setChecked] = useState([])
    // Carica lo stato dei checkbox da localStorage all'avvio del componente
    useEffect(() => {
        const savedChecked = JSON.parse(localStorage.getItem("checked") || "[]");
        setChecked(savedChecked);

        // Controlla se lo stato dei checkbox non è vuoto e se non è già stato salvato in localStorage
        if (savedChecked.length === 0 || JSON.stringify(checked) !== JSON.stringify(savedChecked)) {
            localStorage.setItem("checked", JSON.stringify(checked));
        }
        console.log(checked)
    }, []);

    // Salva lo stato dei checkbox in localStorage ogni volta che viene modificato
    useEffect(() => {
        localStorage.setItem("checked", JSON.stringify(checked));

    }, [checked]);
    const handleChange = (e) => {
        let name = e.target.getAttribute('name')
        let element = document.querySelector('.' + name)
        if (e.target.checked) {
            element.classList.remove('d-none')
            setChecked((prevChecked) => [...prevChecked, name]);
            localStorage.setItem('checked', checked)


        } else {
            setChecked((prevChecked) => prevChecked.filter((item) => item !== name));
            element.classList.add('d-none')
        }
        setTriggerRender(!triggerRender); // Inverti il valore del trigger per forzare il re-rendering
        console.log(name)
    }
    const handleNavigate = (e) => {
        props.setNoteDetails(e.currentTarget.getAttribute('id'))
        console.log( e.currentTarget.getAttribute('id'))
        props.navigateToComponent('notesDetail')
    }
    console.log(checked)
    return (
        <Row
            className={`mx-0 h-100 text-start overflow-hidden ${steps === 1 ? 'align-content-stretch ' : 'align-content-start '}`}>
            <Col md={8} className={'align-items-center'}>
                <b> {t('addNotes')}</b>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                    !props.isFromChechingAnimation ? props.navigateToComponent('viewGlucose') : props.navigateToComponent(props.prevStep)
                }}>OK</Button>
            </Col>

            <Col md={12}
                 className={`align-content-start d-inline-flex flex-column col-md-12 ${steps === 1 ? 'justify-content-around' : 'mt-4'}`}>

                {steps === 1 ? (
                    <>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={10} className={'px-1'}> <Form.Check type={'checkbox'} name="rapidActing"
                                                                         checked={checked ?checked.includes('rapidActing') :'false'}
                                                                         className={'form-check  mt-2 fs-6'}
                                                                         label={t('rapidActing')}
                                                                         onChange={handleChange}/></Col>
                            <Col md={2} className={''}><CiSquarePlus className={`fs-3 me-1 rapidActing ${checked && checked.includes('rapidActing') ? '' : 'd-none'}`}
                                                                     onClick={handleNavigate} id={'rapidActing'}/></Col>
                        </Row>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={10} className={'px-1'}> <Form.Check type={'checkbox'} name="longActing"
                                                                         checked={checked ? checked.includes('longActing') : false}
                                                                         className={'form-check  mt-2 fs-6'}
                                                                         label={t('longActing')}
                                                                         onChange={handleChange}/></Col>
                            <Col md={2} className={''}><CiSquarePlus className={`fs-3 me-1 longActing ${checked && checked.includes('longActing') ? '' : 'd-none'}`}
                                                                     onClick={handleNavigate} id={'longActing'}/></Col>
                        </Row>
                        <Row className={'align-items-center mx-0'}>
                            <Col md={10} className={'px-1'}> <Form.Check type={'checkbox'} name="food"
                                                                         defaultChecked={checked ? checked.includes('food'): false}
                                                                         className={'form-check  mt-2 fs-6'}
                                                                         label={t('food')}
                                                                         onChange={handleChange}/></Col>
                            <Col md={2} className={''}><CiSquarePlus className={`fs-3 me-1 food  ${checked && checked.includes('food') ? '' : 'd-none'}`}
                                                                     onClick={handleNavigate} id={'food'}/></Col>
                        </Row>


                        <Row className={'justify-content-center text-center mb-0 '}>
                            <div className={'text-center'}>
                    <span onClick={() => setStep(2)}>
                    <BiSolidDownArrow style={{
                        fontSize: '30px',
                        color: 'var(--light-blue)',
                        filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                    }}/>

                        </span>
                            </div>

                        </Row>
                    </>
                ) : (
                    <>
                        <Row className={'justify-content-center text-center mb-0 '}>
                            <div className={'text-center'}>
                           <span onClick={() => setStep(1)}>
                                 <BiSolidUpArrow style={{
                                     fontSize: '30px',
                                     color: 'var(--light-blue)',
                                     filter: 'drop-shadow(0px 0px 1px  rgba(0, 0, 0, 1))'
                                 }}/>
                            </span>
                            </div>
                        </Row>
                        <Form.Group>
                            <Row className={'align-items-center mx-0'}>
                                <Col md={10} className={'px-1'}> <Form.Check type={'checkbox'} name="notes"
                                                                             className={'form-check  mt-2 fs-6'}
                                                                             label={t('exercise')}/></Col>
                                <Col md={2} className={''}></Col>
                            </Row>
                            <Row className={'align-items-center mx-0'}>
                                <Col md={10} className={'px-1'}> <Form.Check type={'checkbox'} name="notes"
                                                                             className={'form-check  mt-2 fs-6'}
                                                                             label={t('medication')}/></Col>
                                <Col md={2} className={''}></Col>
                            </Row>

                        </Form.Group>
                    </>
                )}


            </Col>
        </Row>
    )
        ;
}

export default Notes