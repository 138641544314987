import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import applicationSite from "../../assets/img/Sensor/application-site-selection-sm.png"
import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import video from './../../assets/videos/application-site-selection.mp4'
import Video from "../../Components/Layout/Elements/VideoPlayer";
const ApplicationSiteSelection = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow]= useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const videoRef = useRef(null);
    const [hideLabel, setHideLabel]= useState(false)
    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        const videoElement = videoRef.current;
        if (videoElement) {
            const handlePlay = () => {
                console.log('Video started playing');
                setHideLabel(true)
            };

            const handleEnded = () => {
                console.log('Video ended');
                setHideLabel(false)


            };

            // Aggiungere gli event listener
            videoElement.addEventListener('play', handlePlay);
            videoElement.addEventListener('ended', handleEnded);

            // Pulire gli event listener quando il componente si smonta
            return () => {
                videoElement.removeEventListener('play', handlePlay);
                videoElement.removeEventListener('ended', handleEnded);
            };
        }
    }, []);

    const onVideoEnd = () =>  {
        setHideLabel(false)
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.pause();
            // Aggiungere un breve timeout per garantire che il browser aggiorni il poster
            setTimeout(() => {
                videoRef.current.load();
            }, 50);
        }
    }
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("applicationSiteSelect")}</h2>


                <p className={''}>
                    <span>{parse(t("applicationSiteSelect_Text_1"))}  </span>
                </p>


                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span>{parse(t("applicationSiteSelect_Text_2"))}</span>


                </p>


            </Col>
            <Col md={6} className={'align-self-start position-relative'}>

                {video &&  <Video video={video} t={t} videoRef={videoRef} poster={applicationSite}  actionOnStart={() => {  setHideLabel(true)}} actionOnEnd={()=> {onVideoEnd()}} /> }
                {!hideLabel &&  <div className={'applySensorImgTxt'}><p className={'text-start '}>{t("onlyApply")}</p>
                </div>}

            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/professional-options'} nextUrl={'/preparing-application-site'}/>
            </Col>
            <ModalNote show={show}  handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default ApplicationSiteSelection