import {useTranslation} from "../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";

import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";

import WakeUp from "../../Components/ProductUse/TreatmentGuide/WakeUp";
import BeforeBreakfast from "../../Components/ProductUse/TreatmentGuide/BeforeBreakfast";
import InAfternoon from "../../Components/ProductUse/TreatmentGuide/InAfternoon";
import AfterExercise from "../../Components/ProductUse/TreatmentGuide/AfterExercise";
import BeforeDinner from "../../Components/ProductUse/TreatmentGuide/BeforeDinner";
import Lunch from "../../Components/ProductUse/TreatmentGuide/Lunch";

const TreatmentGuideScenarios
    = () => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/checking_app.jpg`)
    }, [lang])
    useEffect(() => {
        console.log(image)
    }, [image])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={12} className={'text-start ps-4'}>

                <h2> {t("treatmentDecisionGuide")} - {t('exampleScenarios')}</h2>
                <Tabs
                    defaultActiveKey="wakeUp"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                    justify
                >
                    <Tab eventKey="wakeUp" title={t("wakeUp")}>
                        <WakeUp/>
                    </Tab>
                    <Tab eventKey="beforeBreakfast" title={t("beforeBreakfast")}>
                        <BeforeBreakfast/>
                    </Tab>
                    <Tab eventKey="lunch" title={t("lunch")}>
                        <Lunch/>
                    </Tab>
                    <Tab eventKey="afternoon" title={t("afternoon")}>
                        <InAfternoon/>
                    </Tab>
                    <Tab eventKey="afterExercise" title={t("afterExercise")}>
                        <AfterExercise/>
                    </Tab>
                    <Tab eventKey="beforeDinner" title={t("beforeDinner")}>
                        <BeforeDinner/>
                    </Tab>
                </Tabs>

                {/*<NavigationButtons t={t} prevUrl={'/understanding-readings'} nextUrl={'/treatment-knowledge-check'}/>*/}
            </Col>


            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default TreatmentGuideScenarios
