import {Col, Row} from "react-bootstrap";

import {useEffect, useState} from "react";
import Graphs from "../../Reader/FirstTimeSetup/HomeScreens/ReviewHistory/Graphs";
import {useTranslation} from "../../../Utils/UseTranslation";

const TimeInTarget = (props) => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);
    useEffect(() => {
        setImage(props.step === 1 ? `/translations/${lang}/ProductUse/ReviewHistory/time_in_target_app.jpg` : `/translations/${lang}/ProductUse/ReviewHistory/time_in_target.jpg`)
    }, [lang, props.step])

    return (
        <Row>
            <Col>
                <p className={'fw-bold bkg-ligth-grey-2 py-2 px-2'}>{t('whatData')}</p>
                <p className={'bkg-ligth-grey-2 pt-4 mb-0 px-2  h-100'}>
                    {t('averageData')}
                </p>
            </Col>
            <Col>
                <p className={'fw-bold bkg-ligth-grey-2 py-2 px-2'}>{t('whatDataShow')}</p>
                <p className={' bkg-ligth-grey-2 pt-4 mb-0 px-2 h-100'}>
                    {props.step === 1 ? t("timeinTargetAppShow") : t('timeinTargetShow')}
                </p>
            </Col>
            <Col>
                <p className={'fw-bold bkg-ligth-grey-2 py-2 px-2'}>{t('example')}</p>
                <p className={' bkg-ligth-grey-2 pt-4 mb-0 px-2 h-100'}>
                    {props.step === 1 ? <img src={image} className={'img-fluid'}/> :
                    <div className={'border-groove'} style={{background: '#fff', borderRadius: '10px'}}>
                        <Graphs grapType={'timeInTarget'} isStatic={true}/>
                    </div> }
                </p>
            </Col>
        </Row>
    )
}
export default TimeInTarget