import {useTranslation} from "../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import {Col, Row, Tab, Tabs} from "react-bootstrap";

import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import SensorReadings from "../../Components/ProductUse/UnderstandingSensor/SensorReadings";
import TrendArrows from "../../Components/ProductUse/UnderstandingSensor/TrendArrows";
import GoingHighLowReader from "../../Components/ProductUse/UnderstandingSensor/GoingHighLowReader";
import HighLowReader from "../../Components/ProductUse/UnderstandingSensor/HighLowReader";
import HiLoReader from "../../Components/ProductUse/UnderstandingSensor/HiLoReader";
import UnderstandingLow from "../../Components/ProductUse/UnderstandingAlarms/UnderstandingLow";
import UnderstandingHigh from "../../Components/ProductUse/UnderstandingAlarms/UnderstandingHigh";
import UnderstandingLoss from "../../Components/ProductUse/UnderstandingAlarms/UnderstandingLoss";

const UnderstandingAlarms  = () => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/checking_app.jpg`)
    }, [lang])
    useEffect(() => {
        console.log(image)
    }, [image])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={12} className={'text-start ps-4'}>

                <h2> {t("understandingAlarms")} - {t('reader')}</h2>
                <Tabs
                    defaultActiveKey="low"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    fill
                    justify
                >
                    <Tab eventKey="low" title={t("lowAlarm")}>
                        <UnderstandingLow/>
                    </Tab>
                    <Tab eventKey="high" title={t("highAlarm")}>
                        <UnderstandingHigh/>
                    </Tab>
                    <Tab eventKey="loss" title={t("signalLossAlarm")}>
                        <UnderstandingLoss/>
                    </Tab>

                </Tabs>


            </Col>
            <Col md={12} className={'text-start align-self-end'}>

                <NavigationButtons t={t} prevUrl={'/using-signalLoss-alarms'} nextUrl={'/checking-with-strip'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default UnderstandingAlarms
