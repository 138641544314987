import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import parse from "html-react-parser";

const LogbookTab = (props) => {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState(null);
    useEffect(() => {
        setImage(props.step=== 1 ?`/translations/${lang}/ProductUse/ReviewHistory/logbook_app.jpg` : `/translations/${lang}/ProductUse/ReviewHistory/logbook.jpg`)
    }, [lang, props.step])
    const logbookShow = parse(t("logbookShow").replace('%PENCIL_READER%', '<img src="/icons/reader_notes.png" />' ))
    return (
        <Row>
            <Col>
                <p className={'fw-bold bkg-ligth-grey-2 py-2 px-2'}>{t('whatData')}</p>
                <p className={'bkg-ligth-grey-2 pt-4 mb-0  px-2  h-100'}>
                    {t('logbookData')}
                </p>
            </Col>
            <Col>
                <p className={'fw-bold bkg-ligth-grey-2 py-2 px-2'}>{t('whatDataShow')}</p>
                <p className={' bkg-ligth-grey-2 pt-4 mb-0  px-2 h-100'}>
                    {props.step === 1 ? t("logbookAppShow") : logbookShow}
                </p>
            </Col>
            <Col>
                <p className={'fw-bold bkg-ligth-grey-2 py-2 px-2'}>{t('example')}</p>
                <p className={' bkg-ligth-grey-2 pt-4 mb-0  px-2 h-100'}>
                   <img src={image} className={'img-fluid'} style={{border: '1px solid grey', borderRadius: '10px'}}/>
                </p>
            </Col>
        </Row>
    )
}
export default LogbookTab