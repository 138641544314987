import {useState} from "react";
import {useTranslation} from "../../Utils/UseTranslation";
import {Col, Form} from "react-bootstrap";

import {HiExclamationTriangle} from "react-icons/hi2";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import ModalNote from "../../Components/Modal/ModalNote";
import parse from "html-react-parser";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";
import {Link, useOutletContext} from "react-router-dom";


const UsingSignalLoss = () => {
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [turnOnOff, setTurnOnOff] = useState(true)
    const [homeBtnBehavior, setHomeBtnBehavior] =useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const [fromSettingAlarms, setFromSettingAlarms]=useState(true)
    const [alarmSound, setAlarmSound] = useOutletContext()
    const handleClose = () => {
        setShow(false)
    }

    const {t} = useTranslation()
    
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("usingSignalLossAlarm")}</h2>
                {/*<p>{t('infoList4_text_2', { measureUnit: i18next.t('dynamicValues.unita_misura')})}</p>*/}
                <p className={'text-orange fw-bold mb-0 mt-2'}> {t("howTo")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    {t("usingLoss_List_1")}   <span>
                        <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('caution'))
                            setText(<ul>
                                <li>
                                    {t('usingHiLo_caution_1')}
                                </li>
                                <li>
                                    {t('usingHiLo_caution_2')}
                                </li>

                            </ul>)
                        }}/>
                    </span></p>
                <hr className={'text-orange my-1 opacity-100'}/>

                <p className={'mb-0 '}>
                    <span>{t("usingLoss_List_2")} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0'}>{t("usingLoss_List_3")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>

                <p className={'text-smaller mb-0 mt-4'}>{t('notes')}</p>
                <ul style={{lineHeight: '1em'}}>
                    <li className={'text-smaller'}>
                        {t('usingLoss_Notes')}
                    </li>
                    <li className={'text-smaller'}>
                        {t('usingLoss_Notes_2')}
                    </li>
                </ul>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0 fw-bold'}>{t('selectOptions')}</p>

                <Form.Check
                    className={'text-orange fw-bold'}
                    name={'alarmSound'}
                    type={'radio'}
                    value={'loss'}
                    label={t("readerLoses")}
                    onChange={() => {
                        setAlarmSound('loss')
                    }}
                />
              {/* <Link className={'btn btn-link text-orange'}><b>{t("readerLoses")}</b></Link> <br />*/}


                <span className={'text-smaller fw-bold'}>{t('note')}</span>
                <p className={'text-smaller'}>{t('loss_note_alarm')}</p>

            </Col>
            <Col md={6} className={'align-self-center'}>
                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior} setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff && <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep} setCurrentStep={setCurrentStep} fromSettingAlarms={fromSettingAlarms} sensorIsAlreadyActive={true}
                                                            firstActivation={false}/>}

                </ReaderEmpty>

                <p className={'fw-bold text-start mt-4 text-smaller'}>{t('disclaimer')}</p>

            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/using-glucose-alarms'} nextUrl={'/understanding-alarms'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>

    )
}

export default UsingSignalLoss