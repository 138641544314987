import {useTranslation} from "react-i18next";
import {Button, Col, Row} from "react-bootstrap";
import {HiExclamationTriangle} from "react-icons/hi2";
import {FiArrowDownRight} from "react-icons/fi";
import {Link, useOutletContext} from "react-router-dom";
import loss from './../../assets/sounds/loss.mp3'
import high from './../../assets/sounds/high.mp3'
import low from './../../assets/sounds/low.mp3'
import useSound from "use-sound";
import {useEffect, useState} from "react";
import sensorSteps_1 from "../../assets/img/Reader/startNewSensor/startSensor_1.jpg";
import sensorSteps_2 from "../../assets/img/Reader/startNewSensor/startSensor_2.jpg";
import sensorSteps_3 from "../../assets/img/Reader/startNewSensor/startSensor_3.jpg";

const GlucoseAlarms = (props) => {
    const {t} = useTranslation()

    const [audio, setAudio] = useState(null);
    const [alarmSound, setAlarmSound] = useOutletContext()
    const [showImage, setShowImage] = useState(false)

    useEffect(() => {
        // Crea una nuova istanza di Audio quando props.alarmSound cambia
        if (props.alarmSound) {
            const newAudio = new Audio(getSoundPath(props.alarmSound));
            newAudio.play();

            // Aggiorna lo stato con la nuova istanza di Audio
            setAudio(newAudio);

            // Funzione di cleanup per fermare il suono quando il componente viene smontato
            return () => {
                newAudio.pause();
                setAudio(null); // Rimuovi l'istanza di Audio quando il suono è stato fermato
            };
        }
    }, [props.alarmSound]);

    // Funzione di utilità per ottenere il percorso del suono in base al tipo di allarme
    const getSoundPath = (alarmSound) => {
        switch (alarmSound) {
            case "low":
                return low;
            case "high":
                return high;
            case "loss":
                return loss;
            default:
                return null;
        }
    };

    /* useEffect(() => {
         let soundToPlay;
         if (props.alarmSound === 'low') {
             soundToPlay = low;
         } else if (props.alarmSound === 'high') {
             soundToPlay = high;
         } else {
             soundToPlay = loss;
         }


         playSound(soundToPlay); // Riproduci il nuovo suono
         console.log(playSound)
         // Pulisci l'effetto per interrompere il suono quando il componente si smonta o quando cambia alarmSound
         return () => {
             stop();
         };
     }, [props.alarmSound, playSound, stop]);*/
    return (
        <Row className={'mx-0 justify-content-between h-100'}>
            {showImage &&
                <>
                    <b className={'text-start'}> {t('checkGlucose')}</b>
                    <img
                        src={'/translations/en/scan_sensor_alarm.png'}
                        alt="Immagine" className={'img-fluid'}/>
                    <b className={'text-start'}>{t('scanCheckGlucose')}</b>
                </>
            }

            {!showImage &&
                <>
                    <Col md={12}>
                        {/*<HiExclamationTriangle className={'text-warning fs-4'}/>*/}
                        <span
                            className={'fw-bold'}> {t(alarmSound === 'low' ? 'lowAlarm' : alarmSound === 'high' ? 'highAlarm' : 'signalLossAlarm')}</span>
                    </Col>
                    <Row className={'fw-bold small mx-0'}>

                        {alarmSound !== 'loss' ? (
                           /* <h2 className={'d-flex justify-content-center align-items-center fw-bold'}
                                style={{fontSize: '60px'}}>
                                {t(alarmSound === 'low' ? 'dynamicValues.usingAlarmsValues.low' : 'dynamicValues.usingAlarmsValues.high')}
                                <span
                                    className={'fs-6 d-inline-flex flex-column'}> <FiArrowDownRight/> <span>{t('dynamicValues.splittedUnit.first')}
                                    <hr className={'m-0'}/>
                                    {t('dynamicValues.splittedUnit.second')}</span>
                                          </span></h2>*/
                            <Col>
                                <img src={'/icons/caution.png'} className={'img-fluid'} style={{width: '150px'}}/>
                            </Col>
                        ) : (
                            <Col><img src={'/icons/reader_screen_signal_loss_alarm.jpg'} className={'img-fluid'}/>
                                <p className={' mt-2 fw-normal'}>{t('scanNow')}</p></Col>
                        ) }


                    </Row>
                    <Row className={'fw-bold small mx-0'}>
                        <Col className={'text-center px-0'}>
                            {props.alarmSound !== 'loss' ? (
                                <Link className={' btn border-groove bkg-ligth-grey text-black fw-400  w-100 py-1'}
                                      style={{fontSize: '14px', lineHeight: '1.2em'}} onClick={() => {
                                    if (audio) {
                                        audio.pause();
                                        setAudio(null);
                                    }
                                    setAlarmSound('')
                                    props.setBehaviorIndex(props.alarmSound === 'low' ?  4 : 3)
                                    props.setSensorIsActive(true)
                                    setShowImage(true)

                                    setTimeout(() => {
                                        props.navigateToComponent('viewGlucose')
                                        setShowImage(false)
                                    }, 3000)
                                }}>{t("dismissAlarm")}</Link>) : (
                                <>
                                    <Row className={'mx-0'}>
                                        <Col md={6} className={'px-1'}>
                                            <Link className={' btn border-groove bkg-ligth-grey text-black fw-400  w-100 py-1'}
                                                  style={{fontSize: '14px', lineHeight: '1.2em'}} onClick={() => {
                                                setAlarmSound('')
                                                props.navigateToComponent('homeScreen')

                                            }}>{t("no")}</Link>
                                        </Col>
                                        <Col md={6} className={'px-1'}>
                                            <Link className={' btn border-groove bkg-ligth-grey text-black fw-400  w-100 py-1'}
                                                  style={{fontSize: '14px', lineHeight: '1.2em'}} onClick={() => {
                                                if (audio) {
                                                    audio.pause();
                                                    setAudio(null);
                                                }
                                                setAlarmSound('')
                                                props.setBehaviorIndex(Math.floor(Math.random() * 5))
                                                props.setSensorIsActive(true)
                                                props.navigateToComponent('viewGlucose')

                                            }}>{t("yes")}</Link>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </>
            }
        </Row>


    )
}

export default GlucoseAlarms