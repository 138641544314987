import {useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";

import {useTranslation} from "react-i18next";

import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import {CiSquarePlus} from "react-icons/ci";
import {HiExclamationTriangle} from "react-icons/hi2";

const GlucoseAlerts = (props) => {
    const {t, i18n} = useTranslation();
    const lang = localStorage.getItem('lang');
    const [triggerRender, setTriggerRender] = useState(false); // Stato di trigger per forzare il re-rendering


    return (
        <Row
            className={`mx-0 text-start overflow-hidden`}>
            <Col md={8} className={'align-items-center'}>
                <b> {props.behavior && (
                    props.behavior[props.behaviorIndex] === 'goingLow' || props.behavior[props.behaviorIndex] === 'Low' ? (
                        <strong><HiExclamationTriangle className={'text-yellow fs-4'}/> {t('Low')}</strong>) :
                        (<strong><HiExclamationTriangle className={'text-yellow fs-4'}/> {t('High')}</strong>)
                )} </b>
            </Col>
            <Col md={4} className={'text-end px-0 position-relative'}>
                <Button className={'position-absolute py-1 pe-3 border-groove upperBtn ps-2 '}
                        style={{top: '0', right: '-10px', borderRadius: '0 0 0 30px'}} onClick={() => {
                    props.navigateToComponent('viewGlucose')
                }}>OK</Button>
            </Col>

            <Col md={12} className={'mt-3'}>
                {props.behavior && (
                    props.behavior[props.behaviorIndex] === 'goingLow' || props.behavior[props.behaviorIndex] === 'Low' ?
                        (<p className={'small'}>{t('lowGlucoseAlert')}</p>) :
                        (<p className={'small'}>{t('highGlucoseAlert')}</p>)
                )}
            </Col>
            {/*<Col md={12} className={'custom-checkbox"'}>*/}
            {/*    <p className={'fw-bold'}>{t("remindToCheck")}</p>*/}
            {/*    <Form.Check inline label={`1${t("hr")}`} type="radio" name="reminder" ></Form.Check>*/}
            {/*    <Form.Check inline label={`2${t("hr")}`} type="radio" name="reminder" ></Form.Check>*/}
            {/*</Col>*/}
        </Row>
    )
        ;
}

export default GlucoseAlerts