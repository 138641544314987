import {Col} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import parse from "html-react-parser";
import chargeReader from './../../assets/img/ProductUse/ChargingReader/reader_connect_cable.png'

import {useEffect, useState} from "react";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";

const ReaderSettingsInfo = () => {
    const {t, i18n} = useTranslation();
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [imageStep1, setImageStep1] = useState()
    const readerSettingInfo_List_1 = parse(t("readerSettingInfo_List_1").replace('%SETTINGS_IMG%', '<img src="/icons/settings.png" /> '))
    const lang = localStorage.getItem('lang');

    const controlSolutionsInfoTXT = []
    for (let i = 1; i < 10; i++) {
        controlSolutionsInfoTXT.push('controlSolutionTesting_Info_' + i)
    }
    useEffect(() => {
        setImageStep1(`/translations/${lang}/ProductUse/ControlSolutionTesting/step_1.png`)
    }, [lang])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={12} className={'align-content-start text-start'}>
                <h2>{t("readerSettingInfo")}</h2>
                <p>{t("readerSettingInfo_Text")}</p>

                <hr className={'text-orange my-1 mt-0 opacity-100'}/>
                <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                    <span>{readerSettingInfo_List_1}
                </span>
                </p>
                <hr className={'text-orange my-1 mt-0 opacity-100'}/>
                <div className={'mb-0 align-items-center justify-content-start'}>
                    <p className={'mb-0'}><b>{t("alarms")}</b>: {t("readerSettingInfo_List_2_2")} </p>
                    <p className={'mb-0'}><b>{t("soundsAndVib")}</b>: {t("readerSettingInfo_List_2_3")} </p>
                    <p className={'mb-0'}><b>{t("timeDate")}</b>: {t("readerSettingInfo_List_2_4")} </p>
                    <p className={'mb-0'}><b>{t("reminders")}</b>: {t("readerSettingInfo_List_2_5")} </p>
                    <p className={'mb-0'}><b>{t("controlSolution")}</b>: {t("readerSettingInfo_List_2_6")} </p>
                    <p className={'mb-0'}><b>{t("language")}</b>: {t("readerSettingInfo_List_2_7")} </p>
                    <p className={'mb-0'}><b>{t("systemStatus")}</b>: {t("readerSettingInfo_List_2_8")} </p>
                    <ul>
                        <li>
                            {t("readerSettingInfo_List_2_9")}
                            <ul>
                                <li>{t("readerSettingInfo_List_2_10")}</li>
                                <li>{t("readerSettingInfo_List_2_11")}</li>
                                <li>{t("readerSettingInfo_List_2_12")}</li>
                                <li>{t("readerSettingInfo_List_2_13")}</li>
                                <li>{t("readerSettingInfo_List_2_14")}</li>
                                <li>{t("readerSettingInfo_List_2_15")}</li>
                            </ul>
                        </li>
                        <li> {t("readerSettingInfo_List_2_16")}</li>
                        <li> {t("readerSettingInfo_List_2_17")}</li>
                    </ul>
                    <p className={'mb-0'}><b>{t("reportSettings")}</b>: {t("readerSettingInfo_List_2_18")} </p>
                    <p className={'mb-0'}><b>{t("readerBasics")}</b>: {t("readerSettingInfo_List_2_19")} </p>
                    <p className={'mb-0'}><b>{t("doseIncrement")}</b>: {t("readerSettingInfo_List_2_20")} </p>
                </div>


                <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>

            </Col>
            <Col className={'align-self-end text-start'}> <NavigationButtons t={t} prevUrl={'/charging-reader'}
                                                                             nextUrl={'/maintenance'}/></Col>
        </>
    )
}

export default ReaderSettingsInfo