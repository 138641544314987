import {Col} from "react-bootstrap";
import {useTranslation} from "../../Utils/UseTranslation";
import parse from "html-react-parser";
import imgStep3 from './../../assets/img/ProductUse/ControlSolutionTesting/step_3.jpg'
import imgStep4 from './../../assets/img/ProductUse/ControlSolutionTesting/step_4.jpg'
import imgStep5 from './../../assets/img/ProductUse/ControlSolutionTesting/step_5.jpg'
import {useEffect, useState} from "react";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";

const ControlSolutionTesting = () => {
    const {t, i18n} = useTranslation();
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [imageStep1, setImageStep1] = useState()
    const controlSolutionTesting_Step1 = t('controlSolutionTesting_Step1', {
        settingsImageToken: '%SETTINGS%',
        settingsImageValue: '<img src="/icons/settings.png" /> '
    });
    const lang = localStorage.getItem('lang');
    const controlSolutionsInfoTXT = []
    for (let i = 1; i < 10; i++) {
        controlSolutionsInfoTXT.push('controlSolutionTesting_Info_' + i)
    }
    useEffect(() => {
        setImageStep1(`/translations/${lang}/ProductUse/ControlSolutionTesting/step_1.png`)
    }, [lang])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <Col md={12} className={'align-content-start text-start '} style={{overflow: 'auto'}}>
            <h2>{t("controlSolutionTesting")}</h2>
            <p>{t("controlSolutionTesting_Text")} <span>
                        <CiCircleInfo className={'fs-3 text-end text-orange'} onClick={() => {
                            setShow(true)
                            setTitle(t('note'))
                            setText(<ul>{controlSolutionsInfoTXT.map(text => <li>{t(text)}</li>)}</ul>)
                        }}/>
                    </span></p>

            <hr className={'text-orange my-1 opacity-100'}/>
            <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                <span className={'h2 me-2'}>1</span> <img src={imageStep1} className={'img-fluid me-5'}
                                                          style={{width: '170px'}}/>
                <span>{controlSolutionTesting_Step1}<br />{t('controlSolutionTesting_Step1_2')} </span>

            </p>
            <hr className={'text-orange my-1 opacity-100'}/>
            <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                <span className={'h2 me-2'}>2</span> <span>{t("controlSolutionTesting_Step2")} </span>
            </p>
            <hr className={'text-orange my-1 opacity-100'}/>
            <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                <span className={'h2 me-2'}>3</span> <img src={imgStep3} className={'img-fluid me-5'}
                                                          style={{width: '170px'}}/>
                <span>{t("controlSolutionTesting_Step3")} </span>
            </p>
            <hr className={'text-orange my-1 opacity-100'}/>
            <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                <span className={'h2 me-2'}>4</span> <img src={imgStep4} className={'img-fluid me-5'}
                                                          style={{width: '170px'}}/>
                <span>{t("controlSolutionTesting_Step4")}<br/><b>{t('note')}:</b> {t("controlSolutionTesting_Step4_1")} </span>
            </p>
            <hr className={'text-orange my-1 opacity-100'}/>
            <p className={'mb-0 d-flex align-items-center justify-content-start'}>
                <span className={'h2 me-2'}>5</span> <img src={imgStep5} className={'img-fluid me-5'}
                                                          style={{width: '170px'}}/>
                <span>{t("controlSolutionTesting_Step5")} </span>
            </p>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
            <NavigationButtons t={t} prevUrl={'/checking-with-strip'} nextUrl={'/living-with-your-system'}/>
        </Col>
    )
}

export default ControlSolutionTesting