import {Col} from "react-bootstrap";
import parse from "html-react-parser";
import {HiExclamationTriangle} from "react-icons/hi2";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import ModalNote from "../../Components/Modal/ModalNote";
import {useState} from "react";
import {useTranslation} from "../../Utils/UseTranslation";
import {CiCircleInfo} from "react-icons/ci";

const AccessingHcp = () => {
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [turnOnOff, setTurnOnOff] = useState(true)
    const [homeBtnBehavior, setHomeBtnBehavior] =useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const [fromSettingAlarms, setFromSettingAlarms]=useState(true)
    const handleClose = () => {
        setShow(false)
    }

    const {t} = useTranslation()
    const accessingHcp_list_1 = t('accessingHcp_list_1', {
        settingsImageToken: '%SETTINGS_IMG%',
        settingsImageValue: '<img src="/icons/settings.png" />'
    });


    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("accessingHcp")}</h2>
                {/*<p>{t('infoList4_text_2', { measureUnit: i18next.t('dynamicValues.unita_misura')})}</p>*/}

                <p className={''}>{t("accessingHcp_text")}</p>
                <p className={'mb-0'}>{t("accessingHcp_text_2")}</p>

                <p className={'text-orange fw-bold mb-0 mt-2  d-flex align-items-center justify-content-between'}> {t("howTo")}   <CiCircleInfo className={'fs-3 text-end text-orange'} onClick={() => {
                    setShow(true)
                    setTitle(t('important'))
                    setText(t('accessingHcp_info'))
                }}/></p>

                <hr className={'text-orange mb-1 mt-0 opacity-100 opacity-100 '}/>

                <p className={'mb-0 '}>
                    <span>{accessingHcp_list_1} </span>
                </p>
                <hr className={'text-orange my-1 opacity-100'}/>
                <p className={'mb-0'}>{t("accessingHcp_list_2")}</p>
                <hr className={'text-orange my-1 opacity-100'}/>
                {/*<p className={'mb-0 d-flex align-items-center justify-content-between'}>*/}
                {/*    <span>{t("accessingHcp_list_3")}</span>*/}

                {/*</p>*/}
                {/*<hr className={'text-orange my-1 opacity-100'}/>*/}
                <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                    <span>{t("accessingHcp_list_4")}</span>

                </p>
                <hr className={'text-orange my-1 opacity-100'}/>

            </Col>
            <Col md={6} className={'align-self-center'}>
                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior} setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff && <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep} setCurrentStep={setCurrentStep} fromSettingAlarms={fromSettingAlarms} isFromProfessional={true}  isFromAddingNotes={true} sensorIsAlreadyActive={true}
                                                            firstActivation={false}/>}

                </ReaderEmpty>
                <p className={'fw-bold text-start mt-4 text-smaller'}>{t('disclaimer')}</p>


            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/change-reader-settings'} nextUrl={'/application-site-selection'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>
    )
}
export default AccessingHcp