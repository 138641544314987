import React, { useState } from 'react';
import LanguageSelector from "../../Components/LanguageSelector/LanguageSelector";
import i18next from "i18next";
import {useNavigate} from "react-router-dom";
import i18n from "../../i18n";
import {useTranslation} from "react-i18next";

const LanguageSelection = () =>  {
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const navigate= useNavigate()


    const handleLanguageSelect = (language) => {
        i18n.changeLanguage(language);
        setSelectedLanguage(language);
        localStorage.setItem("lang", language);
        console.log(selectedLanguage)

        navigate('/home')
    };

    return (
        <>

            <LanguageSelector onSelectLanguage={handleLanguageSelect} />
        </>

    );
}

export default LanguageSelection;