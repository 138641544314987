import {Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";

import {useEffect, useState} from "react";
import {CiCircleInfo} from "react-icons/ci";
import ModalNote from "../../Components/Modal/ModalNote";
import ScreenContainerFirstTime from "../../Components/Reader/FirstTimeSetup/ScreenContainerFirstTime";
import ReaderEmpty from "../../Components/Reader/ReaderEmpty";
import {useTranslation} from "../../Utils/UseTranslation";

const ReviewingHistory = () => {
    const {t} = useTranslation()
    const [turnOnOff, setTurnOnOff] = useState(true)
    const [homeBtnBehavior, setHomeBtnBehavior] = useState()
    const [currentStep, setCurrentStep] = useState('homeScreen');
    const [fromSettingAlarms, setFromSettingAlarms] = useState(true)
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [imageReader, setImageReader] = useState()
    const [imageApp, setImageApp] = useState()
    const lang = localStorage.getItem('lang');

    useEffect(() => {
        setImageReader(`/translations/${lang}/ProductUse/history_home_screen.jpg`)
        setImageApp(`/translations/${lang}/ProductUse/history_home_screen_app.jpg`)
    }, [lang])
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={6} className={'text-start ps-4 '}>


                <Row className={'mx-0 align-self-start'}>
                    <Col md={12}>
                        <h2> {t("reviewingHistory")}</h2>
                        <p className={''}>{t("reviewingHistory_text")}</p>
                        <p className={'text-orange fw-bold mb-0 mt-2 d-flex align-items-center justify-content-between'}> {t("howToWithReader")}
                            <span><CiCircleInfo
                                className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                setShow(true)
                                setTitle(t('note'))
                                setText(t('reviewingHistory_Info'))
                            }}/></span>

                        </p>

                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-0 '}>
                            <span>{t("reviewingHistoryReader_List_1")} </span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100'}/>

                        <p className={'mb-0 d-flex align-items-center justify-content-between'}>
                            <span>{t("reviewingHistoryReader_List_2")}</span>
                        </p>
                        <hr className={'text-orange my-1 opacity-100 mb-1'}/>
                        <p className={'mb-0 '}>
                            <span><b>{t('note')}</b>: {t("reviewingHistoryReader_List_3")} </span>
                        </p>

                    </Col>

                </Row>

                <Row className={'mx-0 align-self-end mt-5'}>
                    <Col className={'align-self-end small'}> <b>{t('disclaimer')}</b></Col></Row>
            </Col>
            <Col md={5} className={'align-content-start '}>
                <ReaderEmpty turnOnOff={turnOnOff} setTurnOnOff={setTurnOnOff} homeBtnBehavior={homeBtnBehavior}
                             setCurrentStep={setCurrentStep} currentStep={currentStep}>
                    {turnOnOff &&
                /*        <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep}
                                                  setCurrentStep={setCurrentStep}
                                                  fromSettingAlarms={fromSettingAlarms}
                                                  isFromAddingNotes={true} sensorIsAlreadyActive={true}
                                                  firstActivation={false}
                                                  t={t} lang={lang}/>*/

                        <ScreenContainerFirstTime setHomeBtnBehavior={setHomeBtnBehavior} currentStep={currentStep}
                                                                                          setCurrentStep={setCurrentStep}
                                                                                          fromSettingAlarms={fromSettingAlarms}    t={t} lang={lang}/>
                    }

                </ReaderEmpty>
            </Col>
            <Row></Row>

            {/*   <Col md={6} className={'text-start ps-4 mt-2'}>
                <Row className={'mx-0'}>
                    <Col md={12}>
                        <p className={'text-orange fw-bold mb-0 mt-2'}> {t("howToWithApp")}</p>

                        <hr className={'text-orange my-1 opacity-100'}/>
                        <p className={'mb-2 d-flex align-items-center justify-content-between'}>
                            <span>{t("reviewingHistoryApp_List")}</span>
                            <span><CiCircleInfo className={'fs-3 ms-2 text-end text-orange'} onClick={() => {
                                setShow(true)
                                setTitle(t('note'))
                                setText(t('reviewingHistory_Info'))
                            }}/></span>
                        </p>

                    </Col>
                </Row>
            </Col>
            <Col md={4} className={'align-content-start offset-1'}>
                <img src={imageApp} className={'img-fluid w-50'}/>

            </Col>*/}
            <Col md={12} className={'text-start align-self-end'}> <NavigationButtons t={t}
                                                                                     prevUrl={'/adding-notes'}
                                                                                     nextUrl={'/reviewing-history-summary'}/></Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>
    )
}
export default ReviewingHistory