import {Col, Row} from "react-bootstrap";

import {useTranslation} from "../../../Utils/UseTranslation";
import {useEffect, useState} from "react";
import './../../../assets/css/ProductUse.css'
import GlucoseReadings from "../../Reader/FirstTimeSetup/HomeScreens/GlucoseReadings";

const WakeUp = () => {
    const {t, i18n} = useTranslation()
    const lang = localStorage.getItem('lang');
    const [image, setImage] = useState([]);
    const [imageApp, setImageApp] = useState([]);
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const wakeUp_Text =t('wakeUp_Text', {
        settingsImageToken: '%BLOOD_IMAGE%',
        settingsImageValue: '/icons/icona-sangue.png'
    });

    const wakeUp_Text_2 =t('wakeUp_Text_2', {
        settingsImageToken: '%BLOOD_IMAGE%',
        settingsImageValue: '/icons/icona-sangue.png'
    });
    const [behaviorIndex, setBehaviorIndex] = useState(0);
    const behavior = ['reader_110']

    useEffect(() => {
        setImage(`/translations/${lang}/ProductUse/treatmentGuide/reader_110.png`)
        setImageApp(`/translations/${lang}/ProductUse/treatmentGuide/app_110.png`)
    }, [lang])
    useEffect(() => {
        console.log(image)
    }, [image])
    return (

        <Row>
            <Col md={4} className={'text-start '}>
                <p className={'p-2'}>
                    <b>{t("whatYouSee")}: </b> {wakeUp_Text}
                </p>
                <p className={'p-2 treatmentColoured'}>
                    <b>{t("whatMeans")}: </b> {wakeUp_Text_2}
                </p>

            </Col>
            <Col md={8} className={'align-items-center justify-content-center d-inline-flex  px-5'}>
                <div className={'text-center'}>
                    <p className={'mx-0'}>
                        <b> {t('reader')}</b>
                    </p>
                    <div style={{width: '230px'}}>
                        <GlucoseReadings navigateToComponent={''} setFirstActivation={''}
                                          sensorIsActive={true} setSensorIsActive={''}
                                          firstActivation={false} behaviorIndex={behaviorIndex}
                                          behavior={behavior} isStatic={true}/></div>
                    {/*<img src={image} className={'img-fluid px-3'}/>*/}
                </div>
               {/* <div className={'text-center'}>
                    <p className={'mx-0'}>
                        <b> {t('app')}</b>
                    </p>
                    <img src={imageApp} className={'img-fluid px-3'}/>
                </div>*/}

            </Col>


            <Col className={'text-start'}>
                {/*<NavigationButtons t={t} prevUrl={'/replacing-sensor'} nextUrl={'/understanding-readings'}/>*/}
            </Col>
        </Row>

    )
}

export default WakeUp