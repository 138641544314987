import {Fab, Action} from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import {MdAdd} from "react-icons/md";
import {useTranslation} from "react-i18next";
import {HiLanguage} from "react-icons/hi2";
import {HiOutlineLogout} from "react-icons/hi";
import {useEffect, useState} from "react";
import {CiSettings} from "react-icons/ci";
import {FiArrowDown, FiArrowUp} from "react-icons/fi";
import {FaSignal, FaSlash} from "react-icons/fa";
import {BiNoSignal} from "react-icons/bi";

const Floating = (props) => {
    const {t} = useTranslation()
    useEffect(() =>  {
        console.log(props.alarmSound)
    }, [props.alarmSound])
    const handleLogout = () => {

        localStorage.clear()
        window.location.reload();
        // location.reload();
    }

    const handleChangeLanguage = () => {
        localStorage.removeItem('lang')
        props.setSelectedLanguage()

    }
    return (
        <>
            <Fab
                mainButtonStyles={{background: 'var(--yellow)', color: 'black'}}
                icon={<CiSettings className={'fs-1'}/>}
                alwaysShowTitle={false}
                onClick={() => {
                }}
            >

                <Action
                    text={t('changeLanguage')}
                    onClick={() => {
                        handleChangeLanguage()
                    }}
                    style={{background: 'var(--yellow)', color: 'black'}}
                >
                    <HiLanguage className={'fs-3'}/>
                </Action>
                <Action
                    style={{background: 'var(--yellow)', color: 'black'}}
                    text={t('logout')}
                    onClick={() => {
                        handleLogout()
                    }}
                >
                    <HiOutlineLogout className={'fs-3'}/>
                </Action>
                {/*{props.isReaderPage &&

                    <Action
                        style={{background: 'var(--blue)'}}
                        text={t('lowAlarm')}
                        onClick={() => {
                            props.setAlarmSound('low')
                        }
                        }
                    >
                        <FiArrowDown className={'fs-3'}/>
                    </Action>

                }*/}
                {/*{props.isReaderPage &&
                    <Action
                        style={{background: 'var(--blue)'}}
                        text={t('highAlarm')}
                        onClick={() => {
                            props.setAlarmSound('high')
                        }
                        }
                    >
                        <FiArrowUp className={'fs-3'}/>
                    </Action>
                }
                {props.isReaderPage &&
                    <Action
                        style={{background: 'var(--blue)'}}
                        text={t('signalLoss')}
                        onClick={() => {

                            props.setAlarmSound('loss')
                        }
                        }
                    >
                        <BiNoSignal className={'fs-3'}/>
                    </Action>
                }*/}
            </Fab>
        </>
    )
}

export default Floating