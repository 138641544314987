
import {useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import parse from "html-react-parser";
import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import applicationSite from "../../assets/img/Sensor/application-site-selection-sm.png"
import './../../assets/css/Sensor/Sensor.css'
import ModalNote from "../../Components/Modal/ModalNote";
import {HiExclamationTriangle} from "react-icons/hi2";
import {CiCircleInfo} from "react-icons/ci";
import {useTranslation} from "../../Utils/UseTranslation";
import {Link} from "react-router-dom";
import video from './../../assets/videos/application-site-prep-sm.mp4'
import Video from "../../Components/Layout/Elements/VideoPlayer";
const PreparingApplicationSite = () => {
    const {t, i18n} = useTranslation()
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const lang = localStorage.getItem('lang');

    const videoRef = useRef(null);

    const playVideo = () => {
        setTimeout(function () {
            if (videoRef.current) {
                videoRef.current.play();

            }
        }, 500)

    };

    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Col md={6} className={'text-start ps-4'}>

                <h2> {t("applicationSitePrepare")}</h2>


                <p className={''}>
                    <span>{parse(t("applicationSitePrepare_Text_1"))}  </span>
                </p>


                <p className={'mb-3 d-flex align-items-center justify-content-between'}>
                    <span><b>{t("note")}: </b> {t("applicationSitePrepare_Info")}</span>


                </p>

            </Col>
            <Col md={6} className={''}>

                {video && <Video video={video} t={t} videoRef={videoRef}  />}
            </Col>
            <Col md={12} className={'text-start align-self-end'}>
                <NavigationButtons t={t} prevUrl={'/application-site-selection'} nextUrl={'/preparing-sensor-applicator'}/>
            </Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>


    )
}

export default PreparingApplicationSite