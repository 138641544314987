import {Col, Nav, Row, Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import NavigationButtons from "../../Components/Layout/Elements/NavigationButtons";
import {HiExclamationTriangle} from "react-icons/hi2";
import {useState} from "react";
import ModalNote from "../../Components/Modal/ModalNote";
import parse from "html-react-parser";
import {CiCircleInfo} from "react-icons/ci";


const LivingWithSystem = () => {
    const {t, i18n} = useTranslation();
    const [show, setShow] = useState()
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const handleClose = () => {
        setShow(false)
    }
    const travelingByAir_info =  parse(t('travelingByAir_info').replace('%CLOCK_IMG%', '<img src="/icons/reader_time.png" />').replace('%SETTINGS_IMG%', '<img src="/icons/settings.png" />'));

    return (
        <>
            <Col md={12} className={'align-content-start text-start'}>
                <h2>{t("livingWSystem")}</h2>
                <Row className={'mt-4 mx-0'}>
                    <Tab.Container className={"mt-5"} id="left-tabs-example" defaultActiveKey="batShower" fill
                                   justify>
                        <Row>
                            <Col sm={3}>

                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="batShower">{t("batShower")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sleeping">{t("sleeping")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="travelingByAir">{t("travelingByAir")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="batShower">
                                        <Row className={'mx-0'}>
                                            <Col>

                                                <p> {t("batShower_Text")}</p>
                                                <p className={'d-flex align-items-center justify-content-between'}>
                                                    <span><b>{t("note")}:</b> {t("batShower_Text_2")}</span>
                                                    <span> <HiExclamationTriangle
                                                        className={'fs-3 text-end text-orange'} onClick={() => {
                                                        setShow(true)
                                                        setTitle(t('caution'))
                                                        setText(parse(t("batShower_Warning")))
                                                    }}/></span></p>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="sleeping">
                                        <Row className={'mx-0'}>
                                            <Col>
                                                <p> {t("sleeping_Text")}</p>


                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="travelingByAir">
                                        <Row className={'mx-0'}>
                                            <Col>
                                                <p className={'d-flex align-items-center justify-content-between'}>
                                                    <span>{t("travelingByAir_text")} </span>
                                                    <span>
                                                        <CiCircleInfo className={'fs-3 text-end text-orange'}
                                                                      onClick={() => {
                                                                          setShow(true)
                                                                          setTitle(t('note'))
                                                                          setText(travelingByAir_info)
                                                                      }}/>
                                                    </span>
                                                </p>
                                                <p><b>{t("important")}: </b>{parse(t("travelingByAir_text_1"))}</p>
                                                <p> {parse(t("travelingByAir_text_2"))}</p>
                                                <p><b>{t("important")}: </b> {parse(t("travelingByAir_text_3"))}</p>


                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Row>
                {/*<Tabs
                    defaultActiveKey="batShower"
                    id="uncontrolled-tab-example"
                    className="mb-3 flex-column"
                    fill
                    justify
                >
                    <Tab eventKey="batShower" title={t("batShower")}>
                        <Row className={'mx-0'}>
                            <Col>
                                <p> {t("batShower_Text")}</p>
                                <p><b>{t("note")}:</b>  {t("batShower_Text_2")}</p>
                                <HiExclamationTriangle className={'fs-3 text-end text-orange'} onClick={() => {
                                    setShow(true)
                                    setTitle(t('caution'))
                                    setText(t("settingOptionalAlarms_Alert"))
                                }}/>
                            </Col>
                        </Row>
                    </Tab>
                    <Tab eventKey="sleeping" title={t("sleeping")}>

                    </Tab>
                    <Tab eventKey="travelingByAir" title={t("travelingByAir")}>

                    </Tab>

                </Tabs>*/}


            </Col>
            <Col className={'text-start align-self-end'}> <NavigationButtons t={t} prevUrl={'/control-solution-testing'}
                                                                             nextUrl={'/charging-reader'}/></Col>
            <ModalNote show={show} handleClose={handleClose} text={text} title={title}></ModalNote>
        </>
    )
}

export default LivingWithSystem